import { MRT_Cell, MRT_Row } from 'material-react-table'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { ProductWasteEntityWithCapacity } from '../types/product-waste-entity-with-capacity'

export const onCapacityOfContainerCellUpdate =
  (columnName: keyof ProductWasteEntityWithCapacity, upsertMutation: any, containerId: string) =>
  ({ cell, row }: { cell: MRT_Cell<ProductWasteEntityWithCapacity>; row: MRT_Row<ProductWasteEntityWithCapacity> }) => ({
    type: 'number',
    required: false,
    onBlur: event => {
      const newValue = event.target.value.trim()
      const finalValue = newValue === '' ? null : +newValue
      const originalValue = row.original[columnName]

      if (!row.original.id || originalValue === newValue) return
      const promise = upsertMutation({
        variables: {
          containerCapacityId: row.original.containerCapacityId,
          data: {
            [columnName]: finalValue,
            containerId: containerId,
            wasteId: row.id
          }
        }
      })

      processPageOperation({
        promise,
        successMessage: `Kapacita kontejneru '${row.original.name}' byla upravena.`
      })
    }
  })
