import { gql, useQuery } from '@apollo/client'
import { SupplierBranchListResponse, SupplierSupplierListResponse } from '../../../graphql/aminTypes'

export const supplierBranchByIdQuery = gql`
  query supplierBranchList($id: ID) {
    supplierBranchList(id: $id) {
      items {
        id
        address {
          city
          street
          streetNumber
          lng
          lat
          zip
        }
        branchServices {
          id
          higherPriority
          isActive
          metrakMargin
          settings
          validFrom
          validTo
          serviceCategory
        }
      }
    }
  }
`

export function useSupplierBranchByIdQuery(id?: string | null, skip?: boolean) {
  return useQuery<{ supplierBranchList: SupplierBranchListResponse }>(supplierBranchByIdQuery, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: skip || !id
  })
}
