import { MRT_ColumnDef } from "material-react-table"
import CheckIcon from '@mui/icons-material/Check'
import { wasteCategoryTypeTranslation } from "../../../services/types/waste-category"
import { ProductWasteEntity } from "../../../graphql/aminTypes"

const useProductWasteListRowTableDefinition = () :MRT_ColumnDef<ProductWasteEntity, any>[] => {

    return [
        {
            header: 'Aktivní',
            accessorKey: 'isActive',
            enableEditing: false,
            size: 30,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word',textAlign: 'center', border: "1px solid #ddd" }
            },
            Cell: ({ cell }) => 
                cell.getValue<boolean>() ? <CheckIcon fontSize="small" style={{ color: "black" }} /> : null,
        },
        {
            header: 'Pořadí zobrazení',
            accessorKey: 'uiOrder',
            enableEditing: true,
            size: 30,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'center', border: "1px solid #ddd" }
            },
        },
        {
            header: 'Jméno',
            accessorKey: 'name',
            enableEditing: true,
            size: 100,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word', border: "1px solid #ddd" }
            },
        },
        {
            header: 'Obrázek',
            accessorKey: 'uiPicture',
            enableEditing: true,
            size: 100,
            Cell: ({ cell }) => {
                const imageName = cell.getValue<string>();
                const imageUrl = imageName
                    ? `https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${imageName}`
                    : null;

                return imageUrl ? <img src={imageUrl} alt="Obrázek odpadu" style={{ width: "80px" }} /> : null;
            },
            muiTableBodyCellProps: {
                style: { border: "1px solid #ddd", }
            },
        },
        {
            header: 'Tooltip',
            accessorKey: 'tooltip',
            enableEditing: true,
            size: 150,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word', border: "1px solid #ddd" }
            },
        },
        {
            header: 'Kategorie odpadu',
            accessorKey: 'wasteCategory',
            enableEditing: true,
            size: 50,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word', border: "1px solid #ddd" }
            },
            Cell: ({ cell }) => {
                return wasteCategoryTypeTranslation[cell.getValue<string>()];
            }
        },
        {
            header: 'Kód odpadu',
            accessorKey: 'wasteType.code',
            enableEditing: true,
            size: 50,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word', border: "1px solid #ddd" }
            },
        },
        {
            header: 'Název odpadu',
            accessorKey: 'wasteType.name',
            enableEditing: true,
            size: 150,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word', border: "1px solid #ddd" }
            },
        },
        {
            header: 'Poznámka',
            accessorKey: 'note',
            enableEditing: true,
            size: 50,
            muiTableBodyCellProps: {
                style: { whiteSpace: 'normal', wordBreak: 'break-word', border: "1px solid #ddd" }
            },
        },
    ]
}

export default useProductWasteListRowTableDefinition