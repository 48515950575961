import { gql, useQuery } from '@apollo/client'
import { contextEndpoint, EndpointEnum } from '../../../../services/appConfiguration/contextEndpoint'
import { ListPouEntity } from '../../../../graphql/aminTypes'

export const suggestPouFromCityQuery = gql`
  query suggestPouFromCity($city: String!, $zip: String) {
    suggestPouFromCity(city: $city, zip: $zip) {
      id
      name
      orp {
        id
        name
      }
    }
  }
`

export function useSuggestPouFromCityQuery() {
  return useQuery<{ suggestPouFromCity: ListPouEntity }>(suggestPouFromCityQuery, {
    context: contextEndpoint(EndpointEnum.admin),
    skip: true
  })
}
