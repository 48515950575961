import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { useCallback, useState } from 'react'

type DeleteDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  deleteItemName: string
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, onClose, onConfirm, deleteItemName }) => {
  return (
    <Dialog open={open} onClose={onClose} id="delete-dialog">
      <DialogTitle id="alert-dialog-title">{`Opravdu chcete smazat ${deleteItemName}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Tato položka bude odstraněna.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Zrušit
        </Button>
        <Button onClick={onConfirm} sx={{ color: '#206BC4' }}>
          Souhlasím
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function useDeleteDialog(): [React.FunctionComponent<void>, { showDialog: (itemName: string, onConfirmFn: () => void) => void }] {
  const [showDeleteContainerCapacityDialog, setShowDeleteContainerCapacityDialog] = useState(false)
  const hideDialog = useCallback(() => setShowDeleteContainerCapacityDialog(false), [])
  const [onConfirmAction, setOnConfirmAction] = useState<{ onConfirmFn: () => void } | undefined>(undefined)
  const [deleteItemName, setDeleteItemName] = useState<string>('')

  const onConfirm = useCallback(() => {
    if (onConfirmAction) onConfirmAction.onConfirmFn()
    setShowDeleteContainerCapacityDialog(false)
  }, [onConfirmAction])

  function showDialog(itemName: string, onConfirmFn: () => void) {
    setDeleteItemName(itemName)
    setOnConfirmAction({ onConfirmFn })
    setShowDeleteContainerCapacityDialog(true)
  }

  const DeleteDialogInstance: React.FC<void> = () => (
    <DeleteDialog open={showDeleteContainerCapacityDialog} onClose={hideDialog} onConfirm={onConfirm} deleteItemName={deleteItemName} />
  )
  return [DeleteDialogInstance, { showDialog }]
}

export default DeleteDialog
