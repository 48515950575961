export const hideTopToolbarDefaultActions = {
  enableGlobalFilter: false,
  enableColumnFilters: false,
  enableColumnOrdering: false,
  enableDensityToggle: false,
  enableHiding: false,
  enableFullScreenToggle: true,
  enableColumnActions: false,
  enableSorting: false
}

export const hideTopToolbarDefaultActionsWithFilters = {
  enableColumnFilters: true,
  enableColumnOrdering: false,
  enableGlobalFilter: false,
  enableDensityToggle: false,
  enableHiding: false,
  enableFullScreenToggle: true,
  enableColumnActions: false,
  enableSorting: false
}
