import { SupplierTransportSupplierLinkEntity, SupplierWasteCollectionSupplierLinkEntity } from '../../../../graphql/aminTypes'
import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'

export const transportSupplierLinkDataDefinition: TableDefinitionTypes<SupplierTransportSupplierLinkEntity> = {
  columnDefinition: {
    id: { hidden: true },
    supplier: { name: 'Dodavatel' },
    transportFlatRateKc: { name: 'Paušál za dopravu', editable: true, type: 'number', columnFormatterType: ColumnFormatterTypes.moneyFormatter },
    includedKmInFlatRate: { name: 'km v paušálu', editable: true, type: 'number' },
    actions: {
      name: 'Akce',
      columnFormatterType: ColumnFormatterTypes.actionsObject,
      align: ColumnAlign.left,
      headerStyle: { width: '70px' }
    }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    supplier: x =>
      `${x.supplierBranch!.supplier?.name}${x.supplierBranch!.specification ? ` - ${x.supplierBranch!.specification}` : ''} (${x.supplierBranch!.address?.city})`,
    transportFlatRateKc: x => x.transportFlatRateKc,
    includedKmInFlatRate: x => x.includedKmInFlatRate,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteLink',
        icon: 'fe-x',
        title: 'Odstranit dodavatele'
      })
      return ret
    }
  }
}
