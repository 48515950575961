import { MaterialReactTable } from 'material-react-table'
import LoadingContainer from '../../components/LoadingContainer'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { useProductContainerListQuery } from './queries/product-container-list.query'
import useProductContainerListTableDefinition from './services/product-container-list-table-definition'
import { useContext, useState } from 'react'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { ModalContext } from '../../components/Modal/ModalProvider'
import AddOrEditContainer from './components/AddOrEditContainer/AddOrEditContainer'
import { RemoveRedEye } from '@mui/icons-material'
import { useDeleteProductContainerMutation } from './mutations/delete-product-container.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import ContainerTopToolbarCustomActions from './components/ContainerTopToolbarCustomActions'
import ProductContainerCapacityOfContainerNew from '../ProductContainerCapacity/ProductContainerCapacityOfContainer'
import { useDeleteDialog } from '../../components/DeleteDialog/DeleteDialog'
import { hideTopToolbarDefaultActions } from '../../components/mui-react-table/hide-top-toolbar-default-actions'

const ProductContainerList = () => {
  const productContainerListQR = useProductContainerListQuery()
  const columnDefinition = useProductContainerListTableDefinition()
  const { showModal, hideModal } = useContext(ModalContext)
  const [deleteProductContainerMut, deleteProductContainerQR] = useDeleteProductContainerMutation()
  const [DeleteDialog, deleteDialogCtrl] = useDeleteDialog()

  const showContainerCapacitiesHandler = (row: any) => {
    if (!row.id) return
    showModal({
      title: 'Kapacity kontejnerů pro odpad ' + row.original.name,
      hideFooter: false,
      modalContent: <ProductContainerCapacityOfContainerNew containerId={row.id} />
    })
  }

  const handleEditRow = (row: any) => {
    const container = productContainerListQR.data?.productContainerList.find(x => x.id === row.id)
    showModal({
      title: 'Editace kontejneru ' + row.original.name + ' ' + row.original.volumeM3 + 'm³',
      modalContent: <AddOrEditContainer containerToEdit={container} exitHandler={hideModal} />,
      hideFooter: true
    })
  }

  const deleteProductContainerHandler = (row: any) => {
    const promise = deleteProductContainerMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Kontejner byl smazán'
    })
  }

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(productContainerListQR, deleteProductContainerQR)}>
      <MuiScopedContainer>
        <MaterialReactTable
          columns={columnDefinition}
          data={productContainerListQR.data?.productContainerList ?? []}
          getRowId={row => row.id}
          enableEditing={true}
          createDisplayMode="row"
          editDisplayMode="row"
          enableBottomToolbar={false}
          enablePagination={false}
          enableTopToolbar={true}
          {...hideTopToolbarDefaultActions}
          renderTopToolbarCustomActions={() => <ContainerTopToolbarCustomActions />}
          state={{ density: 'compact' }}
          defaultColumn={{
            muiTableHeadCellProps: {
              style: { backgroundColor: '#fcce5b' }
            }
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
              <Tooltip title="Kapacity kontejnerů">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => showContainerCapacitiesHandler(row)}>
                  <RemoveRedEye fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Upravit kontejner">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => handleEditRow(row)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Odstranit kontejner">
                <IconButton
                  color="error"
                  onClick={() => {
                    deleteDialogCtrl.showDialog(row.original.name, () => deleteProductContainerHandler(row))
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        {DeleteDialog()}
      </MuiScopedContainer>
    </LoadingContainer>
  )
}

export default ProductContainerList
