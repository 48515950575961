import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { ProductWasteEntity } from '../../../graphql/aminTypes'

export const productWasteListQuery = gql`
  query productWasteList {
    productWasteList {
      id
      isActive
      isForPublic
      name
      tooltip
      wasteCodeId
      wasteCategory
      uiPicture
      uiOrder
      note
      wasteDescription
      acceptedWaste
      unsupportedWaste
      wasteType {
        id
        name
        code
      }
      hasAdditivesSpecification
      childWastes {
        id
        isActive
        isForPublic
        name
        tooltip
        wasteCodeId
        wasteCategory
        parentWasteId
        uiPicture
        uiOrder
        note
        wasteDescription
        acceptedWaste
        unsupportedWaste
        wasteType {
          id
          name
          code
        }
      }
    }
  }
`

export function useProductWasteListQuery() {
  return useQuery<{ productWasteList: ProductWasteEntity[] }>(productWasteListQuery, { fetchPolicy: 'network-only' })
}
