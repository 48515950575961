import React from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip } from 'react-leaflet'
import { SupplierMapItem } from '../SupplierSupplierMap'
import { serviceCategoryColors } from '../../../services/types/service-category-colors'
import { createMultiServiceIcon } from '../../../services/maps/create-multiservice-icon'
import { serviceCategoryTranslation } from '../../../services/types/service-category.type'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'
import { useGetDependentBranchesQuery } from '../queries/dependent-branches.query'
import { SupplierBranchEntity } from '../../../graphql/aminTypes'

export default function SuppliersMap({ supplierBranches, showDependencies }: { supplierBranches: SupplierMapItem[]; showDependencies: boolean }) {
  const supplierId = getParamFromUrl('supplierId')
  const supplierBranchId = getParamFromUrl('supplierBranchId')
  const [selectedBranch, setSelectedBranch] = React.useState<SupplierBranchEntity | null>(null)

  const dependentBranchesQR = useGetDependentBranchesQuery(selectedBranch, showDependencies)
  return (
    <MapContainer center={[50.0741169, 15.3004478]} zoom={8} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png"
        maxZoom={18}
        attribution='Map data: &copy; <a href="http://www.govdata.de/dl-de/by-2-0">dl-de/by-2-0</a>'
      />
      {supplierBranches.map((item, idx) => (
        <>
          <Marker
            key={idx}
            position={[item.gps.lat, item.gps.lng]}
            icon={createMultiServiceIcon(item.serviceCategory.map(sc => serviceCategoryColors[sc]))}
            eventHandlers={{
              mouseover: () => setSelectedBranch(item.branch) // Store clicked branch
            }}
          >
            <Popup>
              <h3>
                <a href={`/configuration/supplier-branch-list?supplierId=${item.supplierId}&supplierBranchId=${item.branchId}`} target="_blank">
                  {item.name} ({item.branch.address.city})
                </a>
              </h3>
              <strong>{item.branch.specification}</strong>
              <br /> {item.serviceCategory.map(c => serviceCategoryTranslation[c]).join(', ')}
            </Popup>
            <Tooltip>
              {item.name} {item.branch.address.city ? `(${item.branch.address.city})` : ''} <br />
              {item.serviceCategory.map(c => serviceCategoryTranslation[c]).join(', ')}
            </Tooltip>
          </Marker>
          {item.supplierId === supplierId && <Marker position={[item.gps.lat, item.gps.lng]}></Marker>}
          {item.branchId === supplierBranchId && <Marker position={[item.gps.lat, item.gps.lng]}></Marker>}
        </>
      ))}

      {selectedBranch?.address.lat &&
        selectedBranch?.address.lng &&
        dependentBranchesQR.data?.getDependentBranches.suppliersWhoUsesMeAsMainSupplier &&
        dependentBranchesQR.data?.getDependentBranches.suppliersWhoUsesMeAsMainSupplier.length > 0 &&
        dependentBranchesQR.data?.getDependentBranches.suppliersWhoUsesMeAsMainSupplier.map((connectedBranch, index) => (
          <Polyline
            key={index}
            positions={[
              [selectedBranch.address.lat as number, selectedBranch.address.lng as number],
              [connectedBranch.address.lat as number, connectedBranch.address.lng as number]
            ]}
            color="blue"
            weight={2}
            opacity={0.7}
          />
        ))}
      {selectedBranch?.address.lat &&
        selectedBranch?.address.lng &&
        dependentBranchesQR.data?.getDependentBranches.suppliersWhoUsesMeAs3rdPartySupplier &&
        dependentBranchesQR.data?.getDependentBranches.suppliersWhoUsesMeAs3rdPartySupplier.length > 0 &&
        dependentBranchesQR.data?.getDependentBranches.suppliersWhoUsesMeAs3rdPartySupplier.map((connectedBranch, index) => (
          <Polyline
            key={index}
            positions={[
              [selectedBranch.address.lat as number, selectedBranch.address.lng as number],
              [connectedBranch.address.lat as number, connectedBranch.address.lng as number]
            ]}
            color="red"
            weight={2}
            opacity={0.7}
          />
        ))}
    </MapContainer>
  )
}
