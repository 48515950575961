import React from 'react'
import TimelineItem from './TimelineItem'
import './style.scss'
import { IStringObject } from '../../../../services/types/IStringObject'
import dayjs from 'dayjs'
import { useOrderHistoryQuery } from './query/order-history.query'
import { changeLogOperationTranslation } from './operation-translation'

export default function Timeline({ orderContentId }: { orderContentId?: number }) {
  const orderHistoryQuery = useOrderHistoryQuery(orderContentId)
  if (!orderHistoryQuery.data?.getOrderHistory || orderHistoryQuery.data?.getOrderHistory.length <= 0) return null

  const activities: IStringObject<TimelineItemType[]> = getFormattedData(
    orderHistoryQuery.data?.getOrderHistory.map(e => ({
      ts: e.createdAt,
      text: changeLogOperationTranslation[e.operation] + (e.newState ? e.newState.name : '') + (e.supplier ? `, dodavatel: ${e.supplier.name}` : ''),
      subtext: e.login ? `Operátor: ${e.login.firstname} ${e.login.lastname}` : undefined
    }))
  )
  const dates: string[] = Object.keys(activities)
  return (
    <div className="time-line-ctnr">
      {dates.map(d => (
        <ul className="time-line" key={d}>
          <li className="time-label">
            <span>{d}</span>
          </li>
          {activities[d].map(({ time, text, key, subtext }) => (
            <TimelineItem time={time} text={text} key={key} subtext={subtext} />
          ))}
        </ul>
      ))}
    </div>
  )
}

export type TimelineItemType = {
  time: string
  text: string
  key: number
  subtext?: string
}

/**
 * Converts array of events in to object having date as the key and list of
 * event for that date as the value
 *
 * @param {Array} items Array of events in the form of ts and text
 * @returns {Object} return object with key as date and values array in events for that date
 */
function getFormattedData(items: { ts: string; text: string; subtext?: string }[]) {
  const activities: IStringObject<TimelineItemType[]> = {}
  items.forEach(({ ts, text, subtext }, index) => {
    const date = dayjs(ts)

    const dateStr = date.format('DD MMM YYYY')
    const list = activities[dateStr] || []
    list.push({
      time: date.format('LT'),
      text,
      key: index,
      subtext: subtext
    })
    activities[dateStr] = list
  })
  return activities
}
