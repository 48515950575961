import { Box, Button, Typography } from "@mui/material";
import AddOrEditContainer from "./AddOrEditContainer/AddOrEditContainer";
import { useContext } from "react";
import { ModalContext } from "../../../components/Modal/ModalProvider";


const ContainerTopToolbarCustomActions = () => {
    const { showModal, hideModal } = useContext(ModalContext);
    
    return(
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            padding="8px"
        >
            <Typography variant="h6" fontWeight="bold">
                Seznam kontejnerů
            </Typography>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#206BC4',
                    color: 'white', 
                    '&:hover': {
                        backgroundColor: '#185A9D',
                    },
                }}
                onClick={() => {
                    showModal({
                        title: 'Přidat kontejner',
                        modalContent: <AddOrEditContainer exitHandler={hideModal} />,
                        hideFooter: true,
                    });
                }}
            >
                Přidat kontejner
            </Button>
        </Box>
    )
}

export default ContainerTopToolbarCustomActions