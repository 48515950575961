import { Maybe } from 'graphql/jsutils/Maybe'

export interface CustomerFormData {
  companyName: string
  companyIdentification: string
  companyTaxId: string
  firstname: string
  lastname: string
  email: string
  phone: string
  invoicingEmail: string
  countryIso: string
  address: {
    street?: Maybe<string> | undefined
    streetNumber: string
    city: string
    zip: string
  }
  b2bMarginCorrection: number
  metrakMarginCorrection: number
  customerDiscount: number
  notes: string
  requireCertificates: boolean
  isResellCompany: boolean
  hasFixedPrice: boolean
  sendMinimumNumberOfEmails: boolean
  advanceAccountsSetup: {
    isUsingAdvanceAccounts: boolean
    negativeBalanceAllowance: number
  }
}

export const customerFormFields: string[] = [
  'companyName',
  'companyIdentification',
  'companyTaxId',
  'firstname',
  'lastname',
  'email',
  'phone',
  'invoicingEmail',
  'countryIso',
  'address',
  'b2bMarginCorrection',
  'metrakMarginCorrection',
  'customerDiscount',
  'notes',
  'isResellCompany',
  'hasFixedPrice',
  'requireCertificates',
  'sendMinimumNumberOfEmails',
  'advanceAccountsSetup'
]
