import { MaterialReactTable } from 'material-react-table'
import LoadingContainer from '../../components/LoadingContainer'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { useProductWasteListQuery } from './queries/product-waste-list.query'
import { useContext, useState } from 'react'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import useProductWasteListTableDefinition from './services/product-waste-list-data-definition'
import WasteTopToolbarCustomActions from './components/WasteTopToolbarCustomActions'
import { Box, IconButton, Tooltip } from '@mui/material'
import { RemoveRedEye } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined'
import { ModalContext } from '../../components/Modal/ModalProvider'
import AddOrEditWastes from './components/AddOrEditWaste/AddOrEditWaste'
import { useDeleteProductWasteMutation } from './mutations/delete-product-waste.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useFillWasteCapacitiesForWasteChildrenMutation } from './mutations/fil-waste-capacities-for-waste-childrens.mutation'
import ProductWasteListRow from './ProductWasteListRow'
import ProductContainerCapacityOfWasteNew from '../ProductContainerCapacity/ProductContainerCapacityOfWaste'
import { useDeleteDialog } from '../../components/DeleteDialog/DeleteDialog'
import { hideTopToolbarDefaultActionsWithFilters } from '../../components/mui-react-table/hide-top-toolbar-default-actions'

const ProductWasteList = () => {
  const columnDefinition = useProductWasteListTableDefinition()
  const productWasteListQR = useProductWasteListQuery()
  const { showModal, hideModal } = useContext(ModalContext)
  const [deleteWasteMut, deleteWasteMR] = useDeleteProductWasteMutation()
  const [fillWasteCapacities, fillWasteCapacitiesMR] = useFillWasteCapacitiesForWasteChildrenMutation()
  const [DeleteDialog, deleteDialogCtrl] = useDeleteDialog()

  const addSubWasteHandler = (row: any) => {
    if (!row.id) return
    const waste = productWasteListQR.data?.productWasteList.find(x => x!.id === row.id)
    showModal({
      title: 'Přidat podskupinu odpadu ' + row.original.name,
      hideFooter: true,
      modalContent: <AddOrEditWastes onExit={hideModal} parentWaste={waste} />
    })
  }
  const editWasteHandler = (row: any) => {
    if (!row.id) return
    const waste = productWasteListQR.data?.productWasteList.find(x => x!.id === row.id)
    showModal({
      title: 'Upravit odpad ' + row.original.name,
      hideFooter: true,
      modalContent: <AddOrEditWastes wasteToEdit={waste} onExit={hideModal} />
    })
  }
  const showContainerCapacitiesHandler = (row: any) => {
    if (!row.id) return
    showModal({
      title: 'Kapacity kontejnerů pro odpad ' + row.original.name,
      hideFooter: false,
      modalContent: <ProductContainerCapacityOfWasteNew wasteId={row.id} />
    })
  }
  const fillWasteCapacitiesForWasteChildrenHandler = (row: any) => {
    const promise = fillWasteCapacities({ variables: { productWasteId: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Kapacity pro podskupiny odpadu byly vyplněny'
    })
  }
  const deleteWasteHandler = (row: any) => {
    const promise = deleteWasteMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Odpad byl odstraněn'
    })
  }
  return (
    <LoadingContainer showLoader={isAnyQueryLoading(productWasteListQR, deleteWasteMR, fillWasteCapacitiesMR)}>
      <MuiScopedContainer>
        <MaterialReactTable
          columns={columnDefinition}
          data={productWasteListQR.data?.productWasteList ?? []}
          getRowId={row => row.id}
          enableEditing={true}
          enableExpanding={true}
          enablePagination={false}
          createDisplayMode="row"
          editDisplayMode="row"
          enableBottomToolbar={false}
          enableTopToolbar={true}
          {...hideTopToolbarDefaultActionsWithFilters}
          renderTopToolbarCustomActions={() => <WasteTopToolbarCustomActions />}
          state={{ density: 'compact' }}
          muiTableProps={{
            sx: {
              borderCollapse: 'collapse',
              '& thead th': { backgroundColor: '#fcce5b', border: '1px solid #ddd' }
            }
          }}
          renderDetailPanel={({ row }) => {
            const formattedChildWastes = (row.original.childWastes ?? []).map((child: any) => ({
              ...child,
              hasAdditivesSpecification: child.hasAdditivesSpecification ?? false
            }))
            return formattedChildWastes.length > 0 ? (
              <Box sx={{ padding: 2, backgroundColor: '#f8f8f8' }}>
                <ProductWasteListRow data={formattedChildWastes} />
              </Box>
            ) : null
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
              <Tooltip title="Přidat produkt s další přísadou">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => addSubWasteHandler(row)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Upravit odpad">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => editWasteHandler(row)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Kapacity kontejnerů">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => showContainerCapacitiesHandler(row)}>
                  <RemoveRedEye fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Doplnit kapacity kontejnerů pro produkty">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => fillWasteCapacitiesForWasteChildrenHandler(row)}>
                  <OfflineBoltOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Odstranit odpad">
                <IconButton
                  color="error"
                  onClick={() => {
                    deleteDialogCtrl.showDialog(row.original.name, () => deleteWasteHandler(row))
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        {DeleteDialog()}
      </MuiScopedContainer>
    </LoadingContainer>
  )
}

export default ProductWasteList
