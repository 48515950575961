import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'
import { TextField } from '@mui/material'
import ToggleField from '../../../components/formComponents/ToggleField'

export default function AdditionalSearchRow({ showSearchState }: { showSearchState: [boolean, React.Dispatch<React.SetStateAction<boolean>>] }) {
  const [partnerSource, setPartnerSource] = useState(getParamFromUrl('partnerSource') ?? '')
  const emptyToDate = getParamFromUrl('emptyToDate')
  const navigate = useNavigate()
  const filterPage = useCallback(
    (action: 'set' | 'reset') => (event: any) => {
      event.preventDefault()
      if (action === 'reset') {
        setPartnerSource('')
        navigate('/order-list')
        return
      }
      if (!partnerSource) {
        navigate('/order-list')
        return
      }

      if (getParamFromUrl('partnerSource') === partnerSource) return
      navigate('/order-list?partnerSource=' + partnerSource)
    },
    [navigate, partnerSource]
  )

  const _handleKeyDown = e => {
    if (e.key === 'Enter') {
      filterPage('set')(e)
    }
  }

  return (
    <div className="row mb-3">
      {showSearchState[0] && (
        <>
          <div className="col-2">
            <label className="form-label mb-2">Filtrovat podle partnera (Bauhaus)</label>
            <div className="input-group input-group-flat">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                value={partnerSource}
                onKeyDown={_handleKeyDown}
                onChange={c => {
                  setPartnerSource(c.target.value)
                }}
              />
              <span className="input-group-text">
                <a href="#" role={'button'} className="link-secondary mr-2" onClick={filterPage('reset')}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M18 6l-12 12"></path>
                    <path d="M6 6l12 12"></path>
                  </svg>
                </a>
                <a href="#" role={'button'} className="link-secondary" onClick={filterPage('set')}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                  </svg>
                </a>
              </span>
            </div>
          </div>
          <div className="col-2">
            <label className="form-check form-switch d-inline-block">
              <input
                name={'emptyToDate'}
                id={'emptyToDate'}
                type="checkbox"
                className="form-check-input"
                value={emptyToDate ?? 'false'}
                onChange={updateEmptyToDate}
              />
              <span className="form-check-label">Prázdný datum odvozu</span>
            </label>
          </div>
        </>
      )}
    </div>
  )

  function updateEmptyToDate(e) {
    const value = e.target.checked ? 'true' : 'false'
    if (value === getParamFromUrl('emptyToDate')) return
    const newSearch = new URLSearchParams(window.location.search)
    newSearch.set('emptyToDate', value)
    navigate('/order-list?' + newSearch.toString())
  }
}
