import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SupplierBranchEntity, SupplierMaterialPriceListEntity } from '../../../graphql/aminTypes'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { MRT_Cell, MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { mrtYesNoOptionalFormatter } from '../../../components/mui-react-table/mrt-formatters'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { onCellUpdate } from './on-price-column-update'

export function supplierMaterialPriceListDataDefinition(
  branchServiceId: string,
  upsertSupplierMaterialPriceListMut: any,
  onUpdateComplete: any
): MRT_ColumnDef<SupplierMaterialPriceListEntity, any>[] {
  return [
    { header: 'Aktivní', enableEditing: false, accessorKey: 'isActive', ...mrtYesNoOptionalFormatter },
    { header: 'Materiál', enableEditing: false, accessorKey: 'material.name', id: 'material' },
    {
      header: 'Cena za 1t',
      enableEditing: true,
      accessorKey: 'priceKc',
      muiEditTextFieldProps: onCellUpdate('priceKc', branchServiceId, upsertSupplierMaterialPriceListMut, onUpdateComplete)
    },
    {
      header: 'Cena za 1m³',
      enableEditing: true,
      accessorKey: 'pricePerCubicKc',
      muiEditTextFieldProps: onCellUpdate('pricePerCubicKc', branchServiceId, upsertSupplierMaterialPriceListMut, onUpdateComplete)
    }
  ]
}
