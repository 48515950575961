import React, { useContext, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { FormComponentBaseInput } from '../components/FormComponentBaseInput'
import { formatAddressToDisplay } from '../../../services/dataToString/formatAddressToDisplay'
import { IFormComponentBaseInput } from '../components/IFormComponentBaseInput'
import { AddressPickerContext } from './AddressPickerWrapper'
import { getStreetNameFromAddrFirstLine } from '../../../services/functions/getStreetNameFromAddrFirstLine'

export interface IAddressPickerType extends IFormComponentBaseInput {
  className?: string
  instanceName?: string
  allowGpsInput?: boolean
  countryIso?: string
  treatGpsAsNumbers?: boolean
  isUsedWithManualGPS?: boolean
}

export default function AddressPicker({ ...otherOptions }: IAddressPickerType) {
  const { setValue, register, setError, clearErrors, watch } = useFormContext()
  const addressPickerContext = useContext(AddressPickerContext)
  const [touched, setTouched] = useState(false)
  const streetRef = useRef<any>()
  const cityRef = useRef<any>()
  const zipRef = useRef<any>()

  register(otherOptions.name, otherOptions.registerOptions)

  const smartFormInstanceName = otherOptions.instanceName ? `smartform-instance-${otherOptions.instanceName}` : ''
  const selectedAddr = watch(otherOptions.name)
  useEffect(() => {
    if (addressPickerContext) addressPickerContext(smartFormInstanceName, announceCallback)
    else {
      if (!window.smartform.rebindAllForms) setTimeout(() => startSmartForm(smartFormInstanceName), 1000)
      else startSmartForm(smartFormInstanceName)
    }
  }, [])

  useEffect(() => {
    if (!window.smartform.rebindAllForms) return
    startSmartForm(smartFormInstanceName)
  }, [otherOptions.countryIso])

  useEffect(() => {
    if (!touched && selectedAddr && !selectedAddr.lat) {
      const keyEvent1 = new KeyboardEvent('keyup', { key: ' ', code: 'Space' })
      const element = document.getElementsByName(`suggestPsc${smartFormInstanceName ?? ''}`)[0]
      if (element) {
        element.removeAttribute('disabled')
        element.focus()
        element.dispatchEvent(keyEvent1)
        element.setAttribute('disabled', 'true')
      }
    }
  }, [smartFormInstanceName, touched, selectedAddr])

  function startSmartForm(instance: string | undefined) {
    const smartform = window.smartform
    const suggestCountryIso = ['CZ', 'SK'].includes(otherOptions.countryIso ?? '') ? otherOptions.countryIso : 'CZ'
    smartform.rebindAllForms(function () {
      window.smartform.getInstance(smartFormInstanceName).addressControl.setCountry(suggestCountryIso)
      window.smartform.getInstance(smartFormInstanceName).addressControl.addValidationCallback(announceCallback)
    })
  }

  function announceCallback(response: any) {
    if (otherOptions.isUsedWithManualGPS && watch(`${otherOptions.name}.lat`)) return
    setTouched(true)
    if (response.result.type === window.smartform.AddressValidationResultType.HIT) {
      const placeData = response.result.addressArray[0]
      clearErrors(otherOptions.name)
      setValue(otherOptions.name, {
        lng: otherOptions.treatGpsAsNumbers ? parseFloat(placeData.COORD_WGS84_LONGITUDE) : placeData.COORD_WGS84_LONGITUDE,
        lat: otherOptions.treatGpsAsNumbers ? parseFloat(placeData.COORD_WGS84_LATITUDE) : placeData.COORD_WGS84_LATITUDE,
        street: getStreetNameFromAddrFirstLine(placeData.FORMATTED_ADDRESS_FIRST_LINE, placeData.WHOLE_NUMBER),
        streetNumber: placeData.WHOLE_NUMBER,
        city: placeData.FORMATTED_ADDRESS_SECOND_LINE,
        zip: placeData.ZIP
      })
    } else {
      setValue(otherOptions.name, undefined)
      setError(otherOptions.name, {
        type: 'required',
        message:
          'Adresa nebyla nalezena. Čísla evidenční uvádějte prosím s označením např: "č.ev. 3". Pokud nemůžete Vaši adresu najít, zadejte nejbližší možnou a přesné přistavení uveďte do poznámky.'
      })
    }
  }

  return (
    <fieldset className={classNames('form-fieldset', otherOptions.className)}>
      <FormComponentBaseInput {...otherOptions} hideValidationError>
        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Ulice č.p./or./ev.</label>
            <input
              ref={streetRef}
              type="text"
              required={otherOptions.required}
              name={`suggestUlice${smartFormInstanceName ?? ''}`}
              id={`suggestUlice${smartFormInstanceName ?? ''}`}
              className={classNames('form-control', 'smartform-address-street-and-number', smartFormInstanceName)}
              defaultValue={selectedAddr ? `${selectedAddr?.street} ${selectedAddr?.streetNumber}` : ''}
              disabled={!!selectedAddr}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Město/obec</label>
            <input
              type="text"
              ref={cityRef}
              required={otherOptions.required}
              name={`suggestObec${smartFormInstanceName ?? ''}`}
              id={`suggestObec${smartFormInstanceName ?? ''}`}
              className={classNames('form-control', 'smartform-address-city', smartFormInstanceName)}
              defaultValue={selectedAddr?.city}
              disabled={!!selectedAddr}
            />
          </div>
          <div className={'col-md-4'}>
            <label className="form-label">PSČ</label>
            <input
              type="text"
              ref={zipRef}
              required={otherOptions.required}
              name={`suggestPsc${smartFormInstanceName ?? ''}`}
              id={`suggestPsc${smartFormInstanceName ?? ''}`}
              className={classNames('form-control', 'smartform-address-zip', smartFormInstanceName)}
              defaultValue={selectedAddr?.zip}
              disabled={!!selectedAddr}
            />
          </div>
        </div>
        {!selectedAddr && touched && (
          <div className="alert alert-danger" role="alert">
            Zapsaná adresa nebyla nalezena. Čísla evidenční uvádějte prosím s označením např: "č.ev. 3". Pokud nemůžete Vaši adresu najít, zadejte nejbližší
            možnou a přesné přistavení uveďte do poznámky.
          </div>
        )}
        {selectedAddr && (
          <div className="form-selectgroup-item flex-fill">
            <span className="text-decoration-underline">Máte vybranou adresu:</span>
            <span className="font-weight-bold"> &nbsp;{formatAddressToDisplay(selectedAddr)}&nbsp;</span>
            {selectedAddr.lat && (
              <a href={`http://www.google.com/maps/place/${selectedAddr.lat},${selectedAddr.lng}`} target="_blank" rel="noopener noreferrer">
                (ověřit na mapě)
              </a>
            )}
            <button type={'button'} onClick={clearAddress} className="btn-link" title={'Zadat znovu'}>
              <i className="fe fe-x" />
            </button>
          </div>
        )}
      </FormComponentBaseInput>
    </fieldset>
  )

  function clearAddress() {
    setValue(otherOptions.name, null)
    streetRef.current.value = ''
    cityRef.current.value = ''
    zipRef.current.value = ''
  }
}
