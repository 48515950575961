import { MRT_Cell, MRT_Row } from 'material-react-table'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { ProductContainerEntityWithCapacity } from '../types/product-container-entity-with-capacity'
import { MutationTuple } from '@apollo/client'

export const onCapacityOfWasteCellUpdate =
  (columnName: keyof ProductContainerEntityWithCapacity, upsertMutation: MutationTuple<any, any>[0], wasteId: string) =>
  ({ cell, row }: { cell: MRT_Cell<ProductContainerEntityWithCapacity>; row: MRT_Row<ProductContainerEntityWithCapacity> }) => ({
    type: 'number',
    required: false,
    onBlur: event => {
      const newValue = event.target.value.trim()
      const finalValue = newValue === '' ? null : +newValue
      const originalValue = row.original[columnName]

      if (!row.original.id || originalValue === newValue) return
      const promise = upsertMutation({
        variables: {
          containerCapacityId: row.original.containerCapacityId,
          data: {
            [columnName]: finalValue,
            containerId: row.original.id,
            wasteId: wasteId
          }
        }
      })

      processPageOperation({
        promise,
        successMessage: `Kapacita odpadu '${row.original.name}' byla upravena.`
      })
    }
  })
