import { MRT_ColumnDef } from 'material-react-table'
import CheckIcon from '@mui/icons-material/Check'
import { ProductMaterialEntity } from '../../../graphql/aminTypes'

const useProductMaterialListTableDefinition = (): MRT_ColumnDef<ProductMaterialEntity, any>[] => {
  return [
    {
      header: 'Aktivní',
      accessorKey: 'isActive',
      enableEditing: false,
      enableColumnFilter: false,
      size: 50,
      muiTableBodyCellProps: {
        style: { textAlign: 'center' }
      },
      Cell: ({ cell }) => (cell.getValue<boolean>() ? <CheckIcon fontSize="small" style={{ color: 'black' }} /> : null)
    },
    {
      header: 'Pro veřejnost',
      accessorKey: 'isForPublic',
      enableEditing: false,
      enableColumnFilter: false,
      size: 50,
      muiTableBodyCellProps: {
        style: { textAlign: 'center' }
      },
      Cell: ({ cell }) => (cell.getValue<boolean>() ? <CheckIcon fontSize="small" style={{ color: 'black' }} /> : null)
    },
    {
      header: 'Pořadí zobrazení',
      accessorKey: 'uiOrder',
      enableEditing: true,
      enableColumnFilter: false,
      size: 50,
      muiTableBodyCellProps: {
        style: { textAlign: 'center' }
      }
    },
    {
      header: 'Jméno',
      accessorKey: 'name',
      enableEditing: true,
      size: 150,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word' }
      }
    },
    {
      header: 'Materiál',
      accessorKey: 'materialComponent.name',
      enableEditing: false,
      size: 150,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word' }
      }
    },
    {
      header: 'Typ',
      accessorKey: 'typeComponent.name',
      enableEditing: false,
      size: 150,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word' }
      }
    },
    {
      header: 'Frakce',
      accessorFn: row => (row.fractionComponent ? row.fractionComponent.name : ''),
      enableEditing: false,
      size: 150,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word' }
      }
    },
    {
      header: '1 kubík na tuny',
      accessorKey: 'cubicToTonnes',
      enableEditing: true,
      enableColumnFilter: false,
      size: 100,
      muiTableBodyCellProps: {
        style: { textAlign: 'center' }
      }
    },
    {
      header: 'Obrázek',
      accessorKey: 'uiPicture',
      enableEditing: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ cell }) => {
        const imgSrc = cell.getValue<string>()
        return imgSrc ? <img src={imgSrc} alt="Material" style={{ width: '80px' }} /> : null
      }
    },
    {
      header: 'Tooltip',
      accessorKey: 'tooltip',
      enableEditing: true,
      enableColumnFilter: false,
      size: 200,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word' }
      }
    },
    {
      header: 'Poznámka',
      accessorKey: 'note',
      enableEditing: true,
      enableColumnFilter: false,
      size: 100,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word' }
      }
    }
  ]
}

export default useProductMaterialListTableDefinition
