import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import { IFormComponentBaseInput } from '../components/IFormComponentBaseInput'
import FormField, { inputType } from '../FormField'

export default function AddressGpsPicker({ ...otherOptions }: IFormComponentBaseInput): JSX.Element {
  const { setValue, register, watch } = useFormContext()
  register(otherOptions.name, otherOptions.registerOptions)

  function storeGps() {
    const currentValue = watch(`${otherOptions.name}manual`)
    const gps = currentValue.gps.split(',')

    setValue(otherOptions.name, {
      lng: +gps[1].trim().replace('E', ''),
      lat: +gps[0].trim().replace('N', ''),
      street: currentValue?.street,
      streetNumber: currentValue?.streetNumber,
      city: currentValue?.city,
      zip: currentValue?.zip
    })
  }

  return (
    <fieldset className={classNames('form-fieldset')}>
      <div className="row mb-3">
        <div className="col-md-4">
          <FormField
            type={inputType.text}
            name={`${otherOptions.name}manual.gps`}
            required={otherOptions.required}
            placeholder={'50.0198644, 14.3318153'}
            label={'GPS souřadnice - WGS84 (degrees)'}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-3">
          <FormField type={inputType.text} label={'Ulice'} required={otherOptions.required} name={`${otherOptions.name}manual.street`} />
        </div>
        <div className="col-md-3">
          <FormField type={inputType.text} label={'č.p./or./ev.'} required={otherOptions.required} name={`${otherOptions.name}manual.streetNumber`} />
        </div>
        <div className="col-md-3">
          <FormField type={inputType.text} label={'Město/obec'} required={otherOptions.required} name={`${otherOptions.name}manual.city`} />
        </div>
        <div className={'col-md-3'}>
          <FormField type={inputType.text} label={'PSČ'} required={otherOptions.required} name={`${otherOptions.name}manual.zip`} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-4">
          <button type="button" className="btn btn-primary ms-auto" onClick={storeGps}>
            Uložit GPS
          </button>
        </div>
      </div>
    </fieldset>
  )
}
