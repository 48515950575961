import { MRT_ColumnDef } from "material-react-table"
import { ProductContainerEntity } from "../../../graphql/aminTypes"


const useProductContainerListTableDefinition = () :MRT_ColumnDef<ProductContainerEntity, any>[] => {

    return [
        {
            header: 'Jméno',
            accessorKey: 'name',
            enableEditing: true,
            maxSize: 100,
        },
        {
            header: 'Objem m³',
            accessorKey: 'volumeM3',
            enableEditing: true,
            maxSize: 100,
        },
        {
            header: 'Tooltip',
            accessorKey: 'tooltip',
            enableEditing: true,
            maxSize: 100,
        },
        {
            header: 'Obrázek',
            accessorKey: 'uiPicture',
            enableEditing: false,
            maxSize: 100,
            Cell: ({ cell }) => {
                const imageName = cell.getValue<string>();
                const imageUrl = imageName
                    ? `https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/containers/${imageName}.svg`
                    : null;
        
                return imageUrl ? <img src={imageUrl} alt="container" style={{ width: "80px" }} /> : null
            },
        },
        {
            header: 'Pořadí zobrazení',
            accessorKey: 'uiOrder',
            enableEditing: true,
            maxSize: 100,
        },
    ]

}

export default useProductContainerListTableDefinition