import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { useSupplierTerminalPriceListsQuery } from './queries/supplier-waste-terminal-price-list.query'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { SupplierTerminalPriceListEntity, SupplierTruckEntity } from '../../graphql/aminTypes'
import { supplierTerminalPriceListDataDefinition } from './services/supplier-terminal-price-list-data-definition'
import BootstrapTable from 'react-bootstrap-table-next'
import { SettingsNode } from './components/SettingsNode'
import WasteTerminalPriceListEditor from './components/SupplierWastePrice/WasteTerminalPriceListEditor'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
import filterFactory from 'react-bootstrap-table2-filter'
import { usePrepareFilterOptions } from '../../scenesAdmin/OrderList/services/usePrepareFilterOptions'
import { wasteCategoryTypeOptions } from '../../services/types/waste-category'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { useDeleteSupplierTerminalPriceListMutation } from './mutations/delete-supplier-terminal-price-list.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { useUpsertSupplierTerminalPriceListMutation } from './components/SupplierWastePrice/mutations/upsert-supplier-terminal-price-list.mutation'
import { useListOfDependenciesQuery4Waste } from './queries/list-of-dependencies.query'
import { useListOfDependenciesDataDefinition } from './services/list-of-dependencies-data-definition'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { MaterialReactTable } from 'material-react-table'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { IoNavigateOutline } from 'react-icons/io5'
const serviceCategory = 'WASTE_TERMINAL'

export default function SupplierServiceWasteTerminal() {
  const { state } = useContext(SelectedSupplierModalContext)
  const { showModal, hideModal } = useContext(ModalContext)
  const listOfDependencies4WasteQR = useListOfDependenciesQuery4Waste(state?.selectedBranch?.id)
  const listOfDependenciesColumns = useListOfDependenciesDataDefinition()
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === serviceCategory)
  const [deleteTerminalPriceListMut, deleteTerminalPriceListQR] = useDeleteSupplierTerminalPriceListMutation()
  const [upsertSupplierTerminalPriceListMut, upsertSupplierTerminalPriceListMR] = useUpsertSupplierTerminalPriceListMutation()

  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation } =
    useBootstrapTableDataMapper<SupplierTerminalPriceListEntity>(supplierTerminalPriceListDataDefinition)
  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(['wasteCategory'], nameForGqlTranslation, { defaultPageSize: 500 })
  const terminalPriceListsQR = useSupplierTerminalPriceListsQuery(currentServiceSetting?.id, gqlQueryParams)

  const { handleTableChange } = useBootstrapTablePagingAndFiltration(pageConfiguration, undefined, true)
  const { filterOptions } = usePrepareFilterOptions()
  filterOptions.addFilterOption('wasteCategory', wasteCategoryTypeOptions)

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    handleTableChange,
    filterOptions4CustomSelectCreator: filterOptions,
    filterDefaultValues: pageConfiguration.filterBy,
    actionsReference: {
      deleteTerminalPriceList: deleteTerminalPriceListHandler
    }
  })
  const tableData = mapDataArrayToTable(terminalPriceListsQR.data?.supplierTerminalPriceLists)

  return (
    <PageCard showLoader={isAnyQueryLoading(terminalPriceListsQR, deleteTerminalPriceListQR, upsertSupplierTerminalPriceListMR)} headerTitle={'Koncovka'}>
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={serviceCategory} settingsNode={<SettingsNode />} />
      {currentServiceSetting && (
        <>
          <button type="button" className="btn btn-primary btn-block mb-2" onClick={addPriceListHandler}>
            Přidat odpad
          </button>
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            filter={filterFactory()}
            onTableChange={handleTableChange}
            classes="table-responsive-lg digiTable"
            noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
            keyField="id"
            data={tableData ? tableData : []}
            columns={columnDefinition}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              autoSelectText: true,
              beforeSaveCell: onCellUpdate
            })}
          />
          <hr />
          <h2>Dodavatelé, kteří vozí na tuto koncovku</h2>
          <MuiScopedContainer>
            <MaterialReactTable
              columns={listOfDependenciesColumns}
              data={listOfDependencies4WasteQR.data?.listOfDependencies ? listOfDependencies4WasteQR.data?.listOfDependencies : []}
              getRowId={row => row.id}
              editDisplayMode="row"
              enableBottomToolbar={false}
              enableTopToolbar={false}
              enableRowActions={true}
              initialState={{
                density: 'compact',
                sorting: [{ id: 'validFrom', desc: false }]
              }}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <a
                    href={`/configuration/supplier/waste-collection-solid?supplierId=${row.original?.supplier?.id}&supplierBranchId=${row.id}`}
                    target={'_blank'}
                  >
                    <IoNavigateOutline />
                  </a>
                </Box>
              )}
            />
          </MuiScopedContainer>
        </>
      )}
    </PageCard>
  )
  function onCellUpdate(oldValue, newValue, row, column) {
    if (!row.id || oldValue === newValue) return
    const item = terminalPriceListsQR.data?.supplierTerminalPriceLists.find(x => x.id === row.id)
    if (!item) return
    const promise = upsertSupplierTerminalPriceListMut({
      variables: {
        branchServiceId: currentServiceSetting?.id,
        terminalPriceListId: row.id,
        data: {
          isActive: item.isActive,
          productWasteId: item.productWaste.id,
          wasteCategory: item.wasteCategory,
          priceKc: +newValue,
          isAcceptedAsTzs: item.isAcceptedAsTzs
        }
      }
    })

    processPageOperation({
      promise,
      successMessage: `Cena byla upravena na ${newValue} Kč`
    })
  }
  function addPriceListHandler() {
    showModal({
      title: 'Přidat ceník',
      modalContent: (
        <WasteTerminalPriceListEditor
          branchServiceId={currentServiceSetting?.id}
          onClose={hideModal}
          doNotShowWastes={terminalPriceListsQR.data?.supplierTerminalPriceLists.map(x => x.productWaste.id) ?? []}
        />
      ),
      hideFooter: true
    })
  }

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex > 4 || columnIndex === 3) return
    const item = terminalPriceListsQR.data?.supplierTerminalPriceLists.find(x => x.id === row.id)
    showModal({
      title: 'Editovat ceník',
      modalContent: <WasteTerminalPriceListEditor branchServiceId={currentServiceSetting?.id} onClose={hideModal} editItem={item} doNotShowWastes={[]} />,
      hideFooter: true
    })
  }

  function deleteTerminalPriceListHandler(row: any) {
    const promise = deleteTerminalPriceListMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Ceník byl smazán'
    })
  }
}
