import { Box, Button, Typography } from "@mui/material"
import { useContext } from "react"
import { ModalContext } from "../../../components/Modal/ModalProvider"
import AddOrEditWastes from "./AddOrEditWaste/AddOrEditWaste"
import TerminalFeeEditor from "./TerminalFeeEditor/TerminalFeeEditor"


const WasteTopToolbarCustomActions = () => {
    const { showModal, hideModal } = useContext(ModalContext);
    
    return(
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            padding="8px"
        >   
            <Box>
                <Typography variant="h6" fontWeight="bold">
                    Seznam odpadů
                </Typography>
            </Box>
            <Box>

                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#206BC4',
                        color: 'white', 
                        '&:hover': {
                            backgroundColor: '#185A9D',
                        },
                        marginRight: '8px',
                    }}
                    onClick={() => {
                        showModal({
                            title: 'Poplatky za odvoz',
                            modalContent: <TerminalFeeEditor onExit={hideModal} />,
                            hideFooter: true
                        })
                    }}
                >
                    Poplatky za odvoz
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#206BC4',
                        color: 'white', 
                        '&:hover': {
                            backgroundColor: '#185A9D',
                        },
                    }}
                    onClick={() => {
                        showModal({
                            title: 'Přidat odpad',
                            modalContent: <AddOrEditWastes onExit={hideModal} />,
                            hideFooter: true
                        })
                    }}
                >
                    Přidat odpad
                </Button>
            </Box>
        </Box>
    )
}

export default WasteTopToolbarCustomActions