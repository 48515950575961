import React from 'react'

/**
 * @usage
 * <TimelineItem time={time} text={text} />
 */
export default function TimelineItem({ time, text, subtext }: { time: string; text: string; subtext?: string }) {
  return (
    <li>
      <i className="fa" />
      <div className="time-line-item d-flex">
        <span className="time flex-column">
          <i className="fa fa-clock-o" /> {time}
        </span>
        <span className="time-line-header">
          {text}
          {subtext && (
            <>
              <br />
              <div className={'text-secondary small'}>{subtext}</div>
            </>
          )}
        </span>
      </div>
    </li>
  )
}
