import { gql, useQuery } from '@apollo/client'
import { SupplierBranchEntity, SupplierTerminalPriceListEntity } from '../../../graphql/aminTypes'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'

const listOfDependenciesQuery = gql`
  query listOfDependencies($branchId: ID!) {
    listOfDependencies(branchId: $branchId, isWaste: true) {
      id
      address {
        city
      }
      specification
      supplier {
        id
        name
      }
    }
  }
`

export function useListOfDependenciesQuery4Waste(branchId?: string) {
  return useQuery<{ listOfDependencies: SupplierBranchEntity[] }>(listOfDependenciesQuery, {
    fetchPolicy: 'network-only',
    variables: { branchId },
    skip: !branchId
  })
}
