import { gql, useQuery } from '@apollo/client'
import { SupplierMaterialPriceListEntity } from '../../../graphql/aminTypes'

export const supplierMaterialPriceListsQuery = gql`
  query supplierMaterialPriceLists($branchServiceId: ID!) {
    supplierMaterialPriceLists(branchServiceId: $branchServiceId) {
      id
      isActive
      priceKc
      pricePerCubicKc
      productMaterialId
      material {
        id
        name
        uiOrder
      }
    }
  }
`

export function useSupplierMaterialPriceListsQuery(branchServiceId?: string, onCompleted?: (data) => void) {
  return useQuery<{ supplierMaterialPriceLists: SupplierMaterialPriceListEntity[] }>(supplierMaterialPriceListsQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId },
    skip: !branchServiceId,
    onCompleted
  })
}
