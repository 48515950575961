import { MaterialReactTable, MRT_Row } from 'material-react-table'
import LoadingContainer from '../../components/LoadingContainer'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useProductContainerCapacityQuery } from './queries/product-container-capacity.query'
import { useProductWasteListFlatQuery } from './queries/product-waste-list.query'
import { useDeleteProductContainerCapacityMutation } from './mutations/delete-product-container-capacity.mutation'
import { useUpsertProductContainerCapacityMutation } from './mutations/upsert-product-container-capacity.mutation'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { ProductWasteEntityWithCapacity } from './types/product-waste-entity-with-capacity'
import useProductContainerCapacityTableDefinition from './services/product-container-capacity-of-container-data-definition'
import { useDeleteDialog } from '../../components/DeleteDialog/DeleteDialog'
import { hideTopToolbarDefaultActions } from '../../components/mui-react-table/hide-top-toolbar-default-actions'

export function ProductContainerCapacityOfContainer({ containerId }: { containerId: string }) {
  const columnDefinition = useProductContainerCapacityTableDefinition(containerId)
  const containerCapacityQR = useProductContainerCapacityQuery({ containerId })
  const wasteListQR = useProductWasteListFlatQuery()
  const [deleteCapacityMut, deleteCapacityQR] = useDeleteProductContainerCapacityMutation()
  const [, upsertCapacityQR] = useUpsertProductContainerCapacityMutation()
  const [DeleteDialog, deleteDialogCtrl] = useDeleteDialog()

  const tableData: ProductWasteEntityWithCapacity[] =
    wasteListQR.data?.productWasteListFlat.map(item => {
      const containerCapacity = containerCapacityQR.data?.productContainerCapacity.find(x => x.wasteId === item.id)
      return {
        ...item,
        capacityTonne: containerCapacity?.capacityTonne ?? undefined,
        calcCapacityTonne: containerCapacity?.calcCapacityTonne ?? undefined,
        containerCapacityId: containerCapacity?.id
      }
    }) ?? []

  function deleteCapacityHandler(row: MRT_Row<ProductWasteEntityWithCapacity>): void {
    const promise = deleteCapacityMut({
      variables: { containerCapacityId: row.original.containerCapacityId }
    })
    processPageOperation({ promise, successMessage: 'Kapacita byla odstraněna' })
  }

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(wasteListQR, containerCapacityQR, deleteCapacityQR, upsertCapacityQR)}>
      <MuiScopedContainer>
        <MaterialReactTable
          columns={columnDefinition}
          data={tableData}
          getRowId={row => row.id}
          enableEditing={true}
          createDisplayMode="row"
          editDisplayMode="cell"
          enableRowActions={true}
          enablePagination={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          {...hideTopToolbarDefaultActions}
          state={{ density: 'compact' }}
          defaultColumn={{
            muiTableHeadCellProps: {
              style: { backgroundColor: '#fcce5b' }
            }
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
              <Tooltip title="Odstranit kapacitu">
                <IconButton
                  color="error"
                  onClick={() => {
                    deleteDialogCtrl.showDialog(`kapacity pro ${row.original.name}`, () => deleteCapacityHandler(row))
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        {DeleteDialog()}
      </MuiScopedContainer>
    </LoadingContainer>
  )
}

export default ProductContainerCapacityOfContainer
