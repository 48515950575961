import React, { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import PageCard from '../../components/Cards/pageCard'
import ToggleField from '../../components/formComponents/ToggleField'
import { useSupplierSupplierListQuery } from './queries/supplier-supplier-list.query'
import { serviceCategoryOptions, ServiceCategoryType } from '../../services/types/service-category.type'
import { defaultValues } from './services/default-values'
import SuppliersMap from './components/SuppliersMap'
import { serviceCategoryColors } from '../../services/types/service-category-colors'
import SelectField from '../../components/formComponents/SelectField'
import L, { LatLngExpression, LatLngLiteral } from 'leaflet'
import { SupplierBranchEntity } from '../../graphql/aminTypes'

export type SupplierMapItem = {
  supplierId: string
  branchId: string
  gps: LatLngLiteral
  name: string
  serviceCategory: ServiceCategoryType[]
  branch: SupplierBranchEntity
}

//https://icons8.com/icons/set/map-point
//https://www.svgviewer.dev/s/343244/sand-bucket
export default function SupplierSupplierMap() {
  const [supplierList, setSupplierList] = React.useState<SupplierMapItem[]>([])
  const formMethods = useForm<any>({ defaultValues, mode: 'onChange' })

  let selectedCategories: ServiceCategoryType[] = []
  const selectedCategoriesData = formMethods.watch('serviceCategory')
  if (selectedCategoriesData)
    selectedCategories = Object.keys(formMethods.watch('serviceCategory')).filter(key => formMethods.watch('serviceCategory')[key]) as ServiceCategoryType[]

  const hasContractWithMetrak = formMethods.watch('hasContractWithMetrak')
  const supplierListQR = useSupplierSupplierListQuery({
    serviceCategories: selectedCategories?.length > 0 ? selectedCategories : undefined,
    hasContractWithMetrak
  })

  async function reloadSuppliers(data, path) {
    let categories = formMethods.watch('serviceCategory')
    let hasContractWithMetrak = formMethods.watch('hasContractWithMetrak')
    if (data) categories = { ...categories, [path.replace('serviceCategory.', '')]: data }

    const resp = await supplierListQR.refetch({
      hasContractWithMetrak,
      serviceCategories: Object.keys(categories).filter(key => categories[key]) as ServiceCategoryType[]
    })
    if (resp.data.supplierSupplierList) {
      let processed: SupplierMapItem[] = []
      for (const supplier of resp.data.supplierSupplierList.items) {
        for (const branch of supplier!.branches!) {
          if (!branch.address.lat || !branch.branchServices) continue
          processed.push({
            supplierId: supplier.id,
            branchId: branch.id,
            gps: L.latLng(branch.address.lat!, branch.address.lng!),
            name: supplier.name,
            serviceCategory: branch.branchServices?.map(item => item.serviceCategory),
            branch: branch
          })
        }
      }
      setSupplierList(processed)
    }
  }

  useEffect(() => {
    reloadSuppliers('', '')
  }, [])
  return (
    <PageCard headerTitle={'Mapa dodavatelů'}>
      <FormProvider {...formMethods}>
        <div className="row">
          <div className="col-md-2">
            <h2>Zobrazené služby</h2>
            <div className={'d-flex flex-row mb-4'}>
              <SelectField
                name={'hasContractWithMetrak'}
                label={'Zasmluvněno'}
                optionsData={[
                  { label: 'Ano', value: true },
                  { label: 'Ne', value: false }
                ]}
                handleChange={() => reloadSuppliers('', '')}
              />
            </div>
            <div className={'d-flex flex-row mb-4'}>
              <ToggleField name={'showDependencies'} label={'Zobrazovat závislosti'} />
            </div>
            <hr />
            {serviceCategoryOptions.map((item, index) => (
              <div key={index} className={'d-flex flex-row mb-4'}>
                <ToggleField
                  name={`serviceCategory.${item.value}`}
                  label={item.label}
                  registerOptions={{ onChange: e => reloadSuppliers(e.target.checked, e.target.name) }}
                />
                <div
                  className={'ml-1 mt-1'}
                  dangerouslySetInnerHTML={{
                    __html: `<div style="background-color: ${serviceCategoryColors[item.value]}; width: 15px; height: 15px; border-radius: 50%; border: 2px solid white; margin-right: 8px;"></div>`
                  }}
                />
              </div>
            ))}
          </div>
          <div className="col-md-10">
            <SuppliersMap supplierBranches={supplierList} showDependencies={formMethods.watch('showDependencies')} />
          </div>
        </div>
      </FormProvider>
    </PageCard>
  )
}
