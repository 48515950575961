import PageCard from '../../components/Cards/pageCard'
import React, { useContext } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { hasQueryData, isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { pageCardHeaderButton } from '../../components/Cards/pageCardHeaderButton'
import { SupplierSupplierEntity } from '../../graphql/aminTypes'
import { supplierSupplierDataDefinition } from './services/supplier-supplier-data-definition'
import { useSupplierSupplierListQuery } from './queries/supplier-supplier-list.query'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import SupplierSupplierEditor from './components/SupplierSupplierEditor/SupplierSupplierEditor'
import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import { useNavigate } from 'react-router-dom'
import { usePrepareFilterOptions } from '../../scenesAdmin/OrderList/services/usePrepareFilterOptions'
import { serviceCategoryOptions } from '../../services/types/service-category.type'
import { useDeleteSupplierSupplierMutation } from './mutations/delete-supplier-supplier.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'

export default function SupplierSupplierList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } =
    useBootstrapTableDataMapper<SupplierSupplierEntity>(supplierSupplierDataDefinition)
  const navigate = useNavigate()

  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(
    ['id', 'name', 'serviceCategory', 'hasContractWithMetrak', 'setupIsFinal'],
    nameForGqlTranslation,
    {
      defaultSort,
      defaultPageSize: 100,
      columnDefinition: supplierSupplierDataDefinition
    }
  )
  const suppliersQR = useSupplierSupplierListQuery(gqlQueryParams)
  const [deleteSupplierMut, deleteSupplierMR] = useDeleteSupplierSupplierMutation()
  const { dispatch } = useContext(SelectedSupplierModalContext)

  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    undefined,
    suppliersQR.data?.supplierSupplierList.total
  )
  const { filterOptions } = usePrepareFilterOptions()
  filterOptions.addFilterOption('serviceCategory', serviceCategoryOptions)
  filterOptions.addFilterOption('hasContractWithMetrak', [
    { value: true, label: 'Ano' },
    { value: false, label: 'Ne' }
  ])
  filterOptions.addFilterOption('setupIsFinal', [
    { value: true, label: 'Ano' },
    { value: false, label: 'Ne' }
  ])

  const columnDefinition = generateColumnListForBootstrapTable({
    filterDefaultValues: pageConfiguration.filterBy,
    filterOptions4CustomSelectCreator: filterOptions,
    currentSort: pageConfiguration.sort,
    columnAction: handleTableClick,
    handleTableChange: handleTableChange,
    actionsReference: {
      editSupplier: editSupplierHandler,
      showOnMap: showOnMapHandler,
      deleteSupplierSupplier: deleteSupplierSupplierHandler
    }
  })

  let suppliersData: any[] = []
  if (suppliersQR.data && hasQueryData(suppliersQR)) suppliersData = mapDataArrayToTable(suppliersQR.data.supplierSupplierList.items)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(suppliersQR, deleteSupplierMR)}
      headerTitle={'Dodavatelé'}
      cardOptions={
        <>
          <span>
            {pageCardHeaderButton(
              'Přidat',
              () =>
                showModal({
                  title: 'Přidat dodavatele',
                  hideFooter: true,
                  modalContent: <SupplierSupplierEditor exitHandler={hideModal} />
                }),
              'AddSupplier'
            )}
          </span>
        </>
      }
    >
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper paginationProps={paginationProps} paginationTableProps={paginationTableProps}>
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné záznamy"</>}
                keyField="id"
                data={suppliersData}
                columns={columnDefinition}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                onTableChange={handleTableChange}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>
    </PageCard>
  )

  function handleTableClick(e: Event, column: any, columnIndex: number, row: any) {
    if (columnIndex !== 7 && columnIndex < 9) {
      dispatch({ type: 'SET_SELECTED_SUPPLIER', payload: suppliersQR.data?.supplierSupplierList.items.find(x => x.id === row.id) })
      navigate('/configuration/supplier-branch-list?supplierId=' + row.id)
    }
  }

  function editSupplierHandler(row: any) {
    if (!row.id) return
    showModal({
      title: 'Upravit ' + row.name,
      hideFooter: true,
      modalContent: <SupplierSupplierEditor supplierSupplierId={row.id} exitHandler={hideModal} />
    })
  }
  function deleteSupplierSupplierHandler(row: any) {
    if (!row.id) return
    if (!window.confirm(`Opravdu chcete smazat dodavatele ${row.name}?`)) return
    const promise = deleteSupplierMut({ variables: { supplierId: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Dodavatel byl odstraněn'
    })
  }
  function showOnMapHandler(row: any) {
    if (!row.id) return
    navigate('/configuration/supplier-map?supplierId=' + row.id)
  }
}
