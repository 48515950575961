import { MRT_ColumnDef } from 'material-react-table'
import { ProductWasteEntityWithCapacity } from '../types/product-waste-entity-with-capacity'
import { onCapacityOfContainerCellUpdate } from './on-capacity-of-container-cell-update'
import { useUpsertProductContainerCapacityMutation } from '../mutations/upsert-product-container-capacity.mutation'

const useProductContainerCapacityTableDefinition = (containerId: string): MRT_ColumnDef<ProductWasteEntityWithCapacity, any>[] => {
  const [upsertCapacityMut] = useUpsertProductContainerCapacityMutation()
  return [
    {
      header: 'Kontejner',
      accessorKey: 'name',
      enableEditing: false,
      size: 200,
      muiTableBodyCellProps: { style: { whiteSpace: 'normal', wordBreak: 'break-word' } }
    },
    {
      header: 'Kapacita pro zákazníky [t]',
      accessorKey: 'capacityTonne',
      enableEditing: true,
      size: 100,
      muiTableBodyCellProps: { style: { textAlign: 'center' } },
      muiEditTextFieldProps: onCapacityOfContainerCellUpdate('capacityTonne', upsertCapacityMut, containerId)
    },
    {
      header: 'Kapacita pro výpočty [t]',
      accessorKey: 'calcCapacityTonne',
      enableEditing: true,
      editVariant: 'text',
      size: 100,
      muiTableBodyCellProps: { style: { textAlign: 'center' } },
      muiEditTextFieldProps: onCapacityOfContainerCellUpdate('calcCapacityTonne', upsertCapacityMut, containerId)
    }
  ]
}

export default useProductContainerCapacityTableDefinition
