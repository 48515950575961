export const changeLogOperationTranslation = {
  addOrderComment: 'Přidání komentáře',
  performZpoEmailDelivery: 'Odeslání ZPO emailem',
  activateDraft: 'Aktivace draftu',
  StateChange: 'Změna stavu na: ',
  approveOrderContent: 'Předschválení administrátorem',
  changeOrderState: 'Manuální změna stavu objednávky',
  updateField: 'Úprava objednávky',
  payOrderFromAdvanceAccount: 'Proplacení ze zákaznického účtu',
  changeOwner: 'Změna vlastníka',
  markManualDelivery: 'Manuální nastavení datumu doručení ZPO',
  runOrderOperation: 'Akce nad objednávkou',
  deleteDraft: 'Smazání draftu',
  createWasteFinal: 'Vytvoření váženky',
  updateZpoDetail: 'Úprava ZPO',
  paymentAction: 'Účetní operace',
  generateZpo: 'Vytvoření ZPO',
  updateOrder: 'Úprava objednávky',
  reGenerateZpo: 'Znovu vygenerování ZPO',
  deleteFile: 'Smazání souboru',
  MatchedFioTransaction: 'Napárování FIO transakce s objednávkou',
  recheckGPPaymentStatus: 'Kontrola stavu platby v GoPay',
  updateOrderPaymentStatusTriggeredByGP: 'Notifikace stavu platby z GoPay',
  newOrderFromAdmin: 'Vytvoření objednávky administrátorem',
  newOrderFromUI: 'Vytvoření objednávky uživatelem'
}
