import { MRT_ColumnDef } from 'material-react-table'
import { ProductContainerEntityWithCapacity } from '../types/product-container-entity-with-capacity'
import { onCapacityOfWasteCellUpdate } from './on-capacity-of-waste-cell-update'
import { useUpsertProductContainerCapacityMutation } from '../mutations/upsert-product-container-capacity.mutation'

const useProductContainerCapacityOfWasteTableDefinition = (wasteId: string): MRT_ColumnDef<ProductContainerEntityWithCapacity, any>[] => {
  const [upsertCapacityMut] = useUpsertProductContainerCapacityMutation()
  return [
    {
      header: 'Typ odpadu',
      accessorKey: 'name',
      enableEditing: false,
      size: 200,
      muiTableBodyCellProps: { style: { whiteSpace: 'normal', wordBreak: 'break-word' } }
    },
    {
      header: 'Kapacita pro zákazníky [t]',
      accessorKey: 'capacityTonne',
      enableEditing: true,
      editVariant: 'text',
      size: 100,
      muiTableBodyCellProps: { style: { textAlign: 'center' } },
      muiEditTextFieldProps: onCapacityOfWasteCellUpdate('capacityTonne', upsertCapacityMut, wasteId)
    },
    {
      header: 'Kapacita pro výpočty [t]',
      accessorKey: 'calcCapacityTonne',
      enableEditing: true,
      editVariant: 'text',
      size: 100,
      muiTableBodyCellProps: { style: { textAlign: 'center' } },
      muiEditTextFieldProps: onCapacityOfWasteCellUpdate('calcCapacityTonne', upsertCapacityMut, wasteId)
    }
  ]
}

export default useProductContainerCapacityOfWasteTableDefinition
