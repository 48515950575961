import { gql, useQuery } from '@apollo/client'
import { SupplierTruckEntity } from '../../../graphql/aminTypes'

export const supplierServiceLoadTransportQuery = gql`
  query supplierServiceLoadTransport($branchServiceId: ID!) {
    supplierServiceLoadTransport(branchServiceId: $branchServiceId) {
      id
      isActive
      capacityTonne
      name
      truckType
      hasHydraulicArm
      hydraulicArmPriceKc
      manipulationPriceKc
      waitingRatePerHourKc
      minimumPriceOfTransportKc {
        price
        serviceCategory
      }
      pricePerKmKc
      supportedCollectionServices
      supportedContainerTypes
      supportedDeliveryServices
    }
  }
`

export function useSupplierServiceLoadTransport(branchServiceId?: string) {
  return useQuery<{ supplierServiceLoadTransport: SupplierTruckEntity[] }>(supplierServiceLoadTransportQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId },
    skip: !branchServiceId
  })
}
