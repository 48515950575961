import { MRT_ColumnDef } from 'material-react-table'
import CheckIcon from '@mui/icons-material/Check'
import { wasteCategoryTypeTranslation } from '../../../services/types/waste-category'
import { ProductWasteEntity } from '../../../graphql/aminTypes'

const useProductWasteListTableDefinition = (): MRT_ColumnDef<ProductWasteEntity, any>[] => {
  return [
    {
      header: 'Aktivní',
      accessorKey: 'isActive',
      enableEditing: false,
      enableColumnFilter: false,
      size: 30,
      muiTableBodyCellProps: {
        style: { textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      },
      Cell: ({ cell }) => (cell.getValue<boolean>() ? <CheckIcon fontSize="small" style={{ color: 'black' }} /> : null)
    },
    {
      header: 'Má přísady',
      accessorKey: 'hasAdditivesSpecification',
      enableEditing: false,
      enableColumnFilter: false,
      size: 30,
      muiTableBodyCellProps: {
        style: { textAlign: 'center', whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      },
      Cell: ({ cell }) => (cell.getValue<boolean>() ? <CheckIcon fontSize="small" style={{ color: 'black' }} /> : null)
    },
    {
      header: 'Jméno',
      accessorKey: 'name',
      enableEditing: true,
      size: 100,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      }
    },
    {
      header: 'Pořadí zobrazení',
      accessorKey: 'uiOrder',
      enableEditing: true,
      enableColumnFilter: false,
      size: 30,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word', textAlign: 'center', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      }
    },
    {
      header: 'Kategorie odpadu',
      accessorKey: 'wasteCategory',
      enableEditing: true,
      size: 50,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      },
      Cell: ({ cell }) => {
        return wasteCategoryTypeTranslation[cell.getValue<string>()]
      }
    },
    {
      header: 'Kód odpadu',
      accessorFn: row => (row.wasteType ? `${row.wasteType.code}` : ''),
      enableEditing: true,
      size: 50,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      }
    },
    {
      header: 'Název odpadu',
      accessorFn: row => (row.wasteType ? `${row.wasteType.name}` : ''),
      enableEditing: true,
      size: 150,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      }
    },
    {
      header: 'Obrázek',
      accessorKey: 'uiPicture',
      enableEditing: false,
      enableColumnFilter: false,
      size: 100,
      muiTableBodyCellProps: {
        style: { borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      },
      Cell: ({ cell }) => {
        const imageName = cell.getValue<string>()
        const imageUrl = imageName ? `https://${process.env.REACT_APP_FE_HOSTNAME}/assets/images/${imageName}` : null
        return imageUrl ? <img src={imageUrl} alt="waste" style={{ width: '80px' }} /> : null
      }
    },
    {
      header: 'Tooltip',
      accessorKey: 'tooltip',
      enableEditing: true,
      size: 150,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      }
    },
    {
      header: 'Poznámka',
      accessorKey: 'note',
      enableEditing: true,
      size: 50,
      muiTableBodyCellProps: {
        style: { whiteSpace: 'normal', wordBreak: 'break-word', borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }
      }
    }
  ]
}

export default useProductWasteListTableDefinition
