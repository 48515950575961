import { MRT_Cell, MRT_Row } from 'material-react-table'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { SupplierMaterialPriceListEntity } from '../../../graphql/aminTypes'
import { toast } from 'react-toastify'

export const onCellUpdate =
  (columnName: string, branchServiceId: string, upsertSupplierMaterialPriceListMut: any, onUpdateComplete: Function) =>
  ({ cell, row }: { cell: MRT_Cell<SupplierMaterialPriceListEntity>; row: MRT_Row<SupplierMaterialPriceListEntity> }) => ({
    type: 'number',
    required: false,
    onBlur: event => {
      const newValue = +event.target.value
      const originalValue = row.original[columnName]
      if (!row.id || originalValue === newValue) return
      if (columnName === 'priceKc' && row.original['pricePerCubicKc'] && !isNaN(row.original['pricePerCubicKc']) && row.original['pricePerCubicKc'] > 0) {
        onUpdateComplete()
        toast.error('Nelze měnit cenu za tunu, když je definována cena z kubík', { autoClose: 5000 })
        return false
      }

      const promise = upsertSupplierMaterialPriceListMut({
        variables: {
          branchServiceId: branchServiceId,
          materialPriceListId: row.original.id,
          data: {
            isActive: row.original.isActive,
            productMaterialId: row.original.material.id,
            priceKc: columnName === 'priceKc' ? +newValue : row.original.priceKc,
            pricePerCubicKc: columnName === 'pricePerCubicKc' ? (newValue ? +newValue : null) : row.original.pricePerCubicKc
          }
        }
      })

      processPageOperation({
        promise,
        successMessage: `Cena byla upravena na ${newValue} Kč`,
        successAction: () => {
          onUpdateComplete()
        }
      })
    }
  })
