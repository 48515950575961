import { SupplierTruckEntity } from '../../../../graphql/aminTypes'
import { ServiceCategoryType } from '../../../../services/types/service-category.type'
import { MRT_ColumnDef } from 'material-react-table'
import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { truckTypeTranslation } from '../../../SupplierServiceLoadTransport/components/SupplierTruck/truck-type'

export function trucksMinimumPriceOfTransportTableDefinition(
  serviceCategory: ServiceCategoryType,
  updateMinimumPriceOfTransportMut: any
): MRT_ColumnDef<SupplierTruckEntity, any>[] {
  const x: MRT_ColumnDef<SupplierTruckEntity, any>[] = [
    {
      header: 'Auto',
      enableEditing: false,
      accessorFn: originalRow => `${originalRow.name} (${truckTypeTranslation[originalRow.truckType]}) - ${originalRow.capacityTonne} t`
    },
    {
      header: 'Minimální cena za dopravu [Kč]',
      accessorFn: row => {
        return row.minimumPriceOfTransportKc?.find(x => x.serviceCategory === serviceCategory)?.price
      },
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'number',
        required: false,
        onBlur: event => {
          const newValue = +event.target.value
          if (!row.id || cell.getValue() === newValue) return

          let variables = { truckId: row.id, serviceCategory, minimumPriceOfTransportKc: newValue ?? undefined }

          const resultPromise = updateMinimumPriceOfTransportMut({
            variables
          })
          processPageOperation({
            promise: resultPromise,
            successMessage: 'Hodnota byla upravena'
          })
        }
      })
    }
  ]
  return x
}
