import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../../services/types/gqlTypes'

const updateMinimumPriceOfTransportMutation = gql`
  mutation updateMinimumPriceOfTransport($truckId: ID!, $serviceCategory: ServiceCategory!, $minimumPriceOfTransportKc: Float!) {
    updateMinimumPriceOfTransport(minimumPriceOfTransportKc: $minimumPriceOfTransportKc, serviceCategory: $serviceCategory, truckId: $truckId) {
      status {
        reason
        result
      }
    }
  }
`

export function useUpdateMinimumPriceOfTransportMutation() {
  return useMutation<{ deleteProductContainer: IMutationResult }>(updateMinimumPriceOfTransportMutation, { refetchQueries: ['supplierServiceLoadTransport'] })
}
