export const defaultValues = {
  isActive: true,
  defaultMetrakMargin: 17,
  'paymentCapabilities.CASH': false,
  'orderNotificationOptions.EMAIL': true,
  'workingHours.0.isWorking': true,
  'workingHours.1.isWorking': true,
  'workingHours.2.isWorking': true,
  'workingHours.3.isWorking': true,
  'workingHours.4.isWorking': true,
  'workingHours.5.isWorking': false,
  'workingHours.6.isWorking': false,
  'workingHours.7.isWorking': false,
  useGpsAddressInput: false
} as any
