import { MRT_ColumnDef } from 'material-react-table'
import { SupplierBranchEntity } from '../../../graphql/aminTypes'

export function useListOfDependenciesDataDefinition(): MRT_ColumnDef<SupplierBranchEntity, any>[] {
  return [
    {
      header: 'Dodavatel',
      accessorFn: row => `${row.supplier?.name} (${row.address.city}) ${row.specification ? `- ${row.specification}` : ''}`,
      enableEditing: false
    }
  ]
}
