import { useSupplierServiceLoadTransportForSolidWaste } from './queries/supplier-service-load-transport.query'
import { ServiceCategoryEnum, ServiceCategoryType } from '../../../services/types/service-category.type'
import React, { useContext } from 'react'
import { SelectedSupplierModalContext } from '../../SelectedSupplierModal/SelectedSupplierModal'
import { WasteCategoryType } from '../../../services/types/waste-category'
import { TransportCategory } from '../../../graphql/aminTypes'
import { trucksMinimumPriceOfTransportTableDefinition } from './services/trucks-minimum-price-of-transport.table-definition'
import { processPageOperation } from '../../../services/formsServices/pageOperationProcessor'
import { useUpdateMinimumPriceOfTransportMutation } from './mutations/update-minimum-price-of-transport.mutation'
import LoadingContainer from '../../../components/LoadingContainer'
import { isAnyQueryLoading } from '../../../services/functions/queryHelpers'
import { MuiScopedContainer } from '../../../components/mui-react-table/MuiScopedContainer'
import { MaterialReactTable } from 'material-react-table'

export function serviceCategoryToWasteCategory(serviceCategory: ServiceCategoryType): WasteCategoryType {
  const serviceCategoryToWasteCategoryMap = {
    [ServiceCategoryEnum.WASTE_COLLECTION_SOLID]: 'SOLID_WASTE',
    [ServiceCategoryEnum.WASTE_COLLECTION_LIQUID]: 'LIQUID_WASTE',
    [ServiceCategoryEnum.WASTE_COLLECTION_ADR]: 'HAZARDOUS_WASTE',
    [ServiceCategoryEnum.WASTE_COLLECTION_SCRAP]: 'SCRAP_WASTE'
  }
  return serviceCategoryToWasteCategoryMap[serviceCategory]
}

export function serviceCategoryToTransportCategory(serviceCategory: ServiceCategoryType): TransportCategory {
  const serviceCategoryToTransportCategoryMap = {
    [ServiceCategoryEnum.TRANSPORT_OF_MATERIAL]: 'MATERIAL',
    [ServiceCategoryEnum.TRANSPORT_OF_CONCRETE]: 'CONCRETE',
    [ServiceCategoryEnum.TRANSPORT_OF_FUEL]: 'FUEL',
    [ServiceCategoryEnum.TRANSPORT_OF_DEK_MACHINERY]: 'MACHINERY'
  }
  return serviceCategoryToTransportCategoryMap[serviceCategory]
}

export default function TruckMinimumPriceOfTransportEditor({ currentServiceCategory }: { currentServiceCategory: ServiceCategoryType }) {
  const { state } = useContext(SelectedSupplierModalContext)
  const loadTransportServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === 'LOAD_TRANSPORT')
  const [updateMinimumPriceOfTransportMut, updateMinimumPriceOfTransportMR] = useUpdateMinimumPriceOfTransportMutation()
  const queryVariables = {
    branchServiceId: loadTransportServiceSetting?.id,
    supportedCollectionService: serviceCategoryToWasteCategory(currentServiceCategory),
    supportedDeliveryService: serviceCategoryToTransportCategory(currentServiceCategory)
  }
  const trucksQR = useSupplierServiceLoadTransportForSolidWaste(queryVariables)

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(trucksQR, updateMinimumPriceOfTransportMR)}>
      <MuiScopedContainer>
        <MaterialReactTable
          getRowId={row => row.id}
          columns={trucksMinimumPriceOfTransportTableDefinition(currentServiceCategory, updateMinimumPriceOfTransportMut)}
          data={trucksQR.data?.supplierServiceLoadTransport || []}
          createDisplayMode={'row'}
          editDisplayMode={'cell'}
          enableClickToCopy={'context-menu'}
          enableEditing={true}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          initialState={{
            density: 'compact',
            isSaving: updateMinimumPriceOfTransportMR.loading,
            pagination: { pageIndex: 0, pageSize: 25 }
          }}
        />
      </MuiScopedContainer>
    </LoadingContainer>
  )

  function updateMinimumPriceOfTransportHandler(oldValue, newValue, row, column) {
    if (!row.id || oldValue === newValue) return

    let variables = { truckId: row.id, serviceCategory: currentServiceCategory, minimumPriceOfTransportKc: +newValue }

    const resultPromise = updateMinimumPriceOfTransportMut({
      variables
    })
    processPageOperation({
      promise: resultPromise,
      successMessage: 'Hodnota byla upravena'
    })
  }
}
