import { gql, useQuery } from '@apollo/client'
import { SupplierBranchEntity } from '../../../graphql/aminTypes'

const listOfDependenciesQuery = gql`
  query listOfDependencies($branchId: ID!) {
    listOfDependencies(branchId: $branchId, transportCategory: MATERIAL) {
      id
      address {
        city
      }
      specification
      supplier {
        id
        name
      }
    }
  }
`

export function useListOfDependenciesQuery4Material(branchId?: string) {
  return useQuery<{ listOfDependencies: SupplierBranchEntity[] }>(listOfDependenciesQuery, {
    fetchPolicy: 'network-only',
    variables: { branchId },
    skip: !branchId
  })
}
