import { gql, useQuery } from '@apollo/client'
import { OrderContentsResult } from '../../../graphql/aminTypes'
import { getParamFromUrl } from '../../../services/functions/getParamFromUrl'

export const orderDetailQuery = gql`
  query orderDetail($orderNumber: Int, $orderId: Int, $orderContentId: Int) {
    orderDetail(orderNumber: $orderNumber, orderId: $orderId, orderContentId: $orderContentId) {
      id
      serviceType
      weightTonnes
      container {
        id
        name
        capacityM3
        uiPicture
      }
      product {
        id
        name
        uiPicture
        tonToCubes
        material {
          id
          name
          uiPicture
        }
      }
      terminal {
        id
        name
      }
      supplier {
        id
        name
        iczNumber
        zpoNote
        gps {
          coordinates
        }
      }
      approvedAt
      approvedBy {
        id
        firstname
        lastname
        email
      }
      supplierProfitWoVatKc
      supplierProfitOriginalWoVatKc
      arriveTime
      collectionTime
      containerFromDate
      containerToDate
      containerCount
      distanceTotalKm
      codToCollect
      totalForContentWoVatKc
      adminNote
      supplierNote
      customerNote
      wasteDescription
      filesCount
      onsiteContactFirstname
      onsiteContactLastname
      onsiteContactPhone
      orderContentState {
        id
        name
        sysName
      }
      orderContentPrices {
        id
        priceWoVatKc
        orderContentPriceItem {
          id
          name
          uiOrder
          partOfTotalPrice
        }
      }
      order {
        id
        uuid
        orderNumber
        b2bCustomerId
        firstname
        lastname
        companyName
        targetCompanyName
        targetCompanyIco
        email
        phone
        street
        streetNumber
        city
        zip
        totalWoVatKc
        totalInclVatKc
        totalInclVatRoundingKc
        refundedKc
        paymentType
        paymentStatus
        orderNote
        ico
        dic
        companyCountryIso
        invoicingNote
        invoicingNoteDone
        goPayPaymentStatus
        goPayTransactionId
        createdAt
        updatedAt
        requireCertificateState
        customer {
          id
          isResellCompany
          companyName
          firstname
          lastname
        }
        accountingDocuments {
          id
          type
          amountInclVatKc
          isFullRefund
          documentUrl
          dueDate
          note
        }
        adminOwner {
          lastname
          firstname
        }
        gps {
          coordinates
        }
        companyResidence {
          street
          streetNumber
          city
          zip
          countryIso
        }
        createdBy {
          id
          firstname
          lastname
          email
        }
        updatedBy {
          id
          firstname
          lastname
          email
        }
      }
    }
  }
`
export function useOrderDetailQuery(setupFields?: (data: { orderDetail: OrderContentsResult }) => void) {
  return useQuery<{ orderDetail: OrderContentsResult }>(orderDetailQuery, {
    fetchPolicy: 'network-only',
    variables: {
      orderNumber: parseInt(getParamFromUrl('orderNumber') ?? ''),
      orderId: parseInt(getParamFromUrl('orderId') ?? ''),
      orderContentId: parseInt(getParamFromUrl('orderContentId') ?? '')
    },
    onCompleted: setupFields
  })
}
