import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { ProductMaterialEntity } from '../../../graphql/aminTypes'

export const productMaterialListQuery = gql`
  query productMaterialList {
    productMaterialList {
      isActive
      id
      name
      tooltip
      uiOrder
      uiPicture
      cubicToTonnes
      isForPublic
      materialId
      typeId
      fractionId
      note
      fractionComponent {
        name
        id
      }
      materialComponent {
        id
        name
      }
      typeComponent {
        id
        name
      }
    }
  }
`

export function useProductMaterialListQuery() {
  return useQuery<{ productMaterialList: ProductMaterialEntity[] }>(productMaterialListQuery, {
    fetchPolicy: 'network-only'
  })
}
