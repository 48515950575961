import { gql, useQuery } from '@apollo/client'
import { MissingConfigurationType } from '../../../graphql/aminTypes'

export const missingSupplierConfigurationQuery = gql`
  query missingSupplierConfiguration {
    missingSupplierConfiguration {
      supplierBranch {
        id
        specification
        address {
          city
          street
        }
        supplier {
          id
          name
        }
      }
      foundProblem
      serviceCategory
    }
  }
`

export function useMissingSupplierConfigurationQuery() {
  return useQuery<{ missingSupplierConfiguration: MissingConfigurationType[] }>(missingSupplierConfigurationQuery, {
    fetchPolicy: 'network-only'
  })
}
