import { RemoveRedEye } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { MaterialReactTable } from 'material-react-table'
import LoadingContainer from '../../components/LoadingContainer'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { ProductWasteEntity } from '../../graphql/aminTypes'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import useProductWasteListRowTableDefinition from './services/product-waste-list-row-data-definition'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useContext } from 'react'
import { ModalContext } from '../../components/Modal/ModalProvider'
import AddOrEditWastes from './components/AddOrEditWaste/AddOrEditWaste'
import { useDeleteProductWasteMutation } from './mutations/delete-product-waste.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import ProductContainerCapacityOfWasteNew from '../ProductContainerCapacity/ProductContainerCapacityOfWaste'
import { useDeleteDialog } from '../../components/DeleteDialog/DeleteDialog'
import { hideTopToolbarDefaultActionsWithFilters } from '../../components/mui-react-table/hide-top-toolbar-default-actions'

const ProductWasteListRow = ({ data }: { data: ProductWasteEntity[] }) => {
  const columnDefinition = useProductWasteListRowTableDefinition()
  const { showModal, hideModal } = useContext(ModalContext)
  const [deleteWasteMut, deleteWasteQR] = useDeleteProductWasteMutation()
  const [DeleteDialog, deleteDialogCtrl] = useDeleteDialog()

  const editWasteHandler = (row: any) => {
    if (!row.id) return
    const waste = data.find(x => x.id === row.id)
    showModal({
      title: 'Editace odpadu ' + row.original.name,
      hideFooter: true,
      modalContent: <AddOrEditWastes onExit={hideModal} wasteToEdit={waste} />
    })
  }
  const showContainerCapacitiesHandler = (row: any) => {
    if (!row.id) return
    showModal({
      title: 'Kapacity kontejnerů pro odpad ' + row.original.name,
      hideFooter: false,
      modalContent: <ProductContainerCapacityOfWasteNew wasteId={row.id} />
    })
  }
  const deleteWasteHandler = (row: any) => {
    const promise = deleteWasteMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Odpad byl odstraněn'
    })
  }

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(deleteWasteQR)}>
      <MuiScopedContainer>
        <MaterialReactTable
          columns={columnDefinition}
          data={data}
          getRowId={row => row.id}
          enableEditing={true}
          enablePagination={false}
          enableTopToolbar={false}
          createDisplayMode="row"
          editDisplayMode="row"
          enableBottomToolbar={false}
          {...hideTopToolbarDefaultActionsWithFilters}
          state={{ density: 'compact' }}
          defaultColumn={{
            muiTableHeadCellProps: {
              style: { backgroundColor: '#3498db', border: '1px solid #ddd' }
            }
          }}
          muiTableProps={{ sx: { borderCollapse: 'collapse' } }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
              <Tooltip title="Upravit odpad">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => editWasteHandler(row)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Kapacity kontejnerů">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => showContainerCapacitiesHandler(row)}>
                  <RemoveRedEye fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Odstranit odpad">
                <IconButton
                  color="error"
                  onClick={() => {
                    deleteDialogCtrl.showDialog(row.original.name, () => deleteWasteHandler(row))
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        {DeleteDialog()}
      </MuiScopedContainer>
    </LoadingContainer>
  )
}

export default ProductWasteListRow
