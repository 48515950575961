import { moneyToLocaleString } from '../../services/dataToString/decimalToLocaleString'
import React from 'react'

export const mrtMoneyFormatter = {
  Cell: ({ cell }) => {
    const value = cell.getValue()
    return moneyToLocaleString(value, 'CZK', true)
  }
}

export const mrtYesNoOptionalFormatter = {
  Cell: ({ cell }) => {
    const value = cell.getValue()
    switch (value) {
      case true:
      case 'yes':
        return <i className="fe fe-check" />
      case false:
      case 'no':
        return <i className="fe fe-x" />
      case 'optional':
        return <i className="fe fe-help-circle" />
      default:
        return cell
    }
  }
}
