import { SelectedSupplierModalContext } from '../SelectedSupplierModal/SelectedSupplierModal'
import React, { useContext, useState } from 'react'
import PageCard from '../../components/Cards/pageCard'
import SupplierServiceEnabler from '../common/components/SupplierServiceEnabler/SupplierServiceEnabler'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { SupplierMaterialPriceListEntity } from '../../graphql/aminTypes'
import { supplierMaterialPriceListDataDefinition } from './services/supplier-material-price-list-data-definition'
import { SettingsNode } from './components/SettingsNode'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import { useSupplierMaterialPriceListsQuery } from './queries/supplier-material-price-lists.query'
import SupplierEditMaterialPrice from './components/SupplierEditMaterialPrice/SupplierEditMaterialPrice'
import { useDeleteSupplierMaterialPriceListMutation } from './mutations/delete-supplier-material-price-list.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useUpsertSupplierMaterialPriceListMutation } from './components/SupplierEditMaterialPrice/mutations/upsert-supplier-material-price-list.mutation'
import { useListOfDependenciesDataDefinition } from '../SupplierServiceWasteTerminal/services/list-of-dependencies-data-definition'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { MaterialReactTable, MRT_Row } from 'material-react-table'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { IoNavigateOutline } from 'react-icons/io5'
import { useListOfDependenciesQuery4Material } from './queries/list-of-dependencies.query'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const serviceCategory = 'MATERIAL_SELLER'
export default function SupplierServiceMaterialPriceList() {
  const { state } = useContext(SelectedSupplierModalContext)
  const { showModal, hideModal } = useContext(ModalContext)
  const listOfDependencies4MaterialQR = useListOfDependenciesQuery4Material(state?.selectedBranch?.id)
  const listOfDependenciesColumns = useListOfDependenciesDataDefinition()
  const currentServiceSetting = state.selectedBranch?.branchServices?.find(x => x.serviceCategory === serviceCategory)
  const [deleteMaterialPriceListMut, deleteMaterialPriceListMR] = useDeleteSupplierMaterialPriceListMutation()
  const [upsertSupplierMaterialPriceListMut, upsertSupplierMaterialPriceListMR] = useUpsertSupplierMaterialPriceListMutation()
  const [materialData, setMaterialData] = useState<SupplierMaterialPriceListEntity[]>([])
  const materialPriceListQR = useSupplierMaterialPriceListsQuery(currentServiceSetting?.id, data => setMaterialData(data.supplierMaterialPriceLists))
  return (
    <PageCard
      showLoader={isAnyQueryLoading(materialPriceListQR, deleteMaterialPriceListMR, upsertSupplierMaterialPriceListMR)}
      headerTitle={'Prodej materiálu'}
    >
      <SupplierServiceEnabler currentServiceSetting={currentServiceSetting} serviceCategory={serviceCategory} settingsNode={<SettingsNode />} />
      {currentServiceSetting && (
        <>
          <MuiScopedContainer>
            <MaterialReactTable
              columns={supplierMaterialPriceListDataDefinition(currentServiceSetting.id, upsertSupplierMaterialPriceListMut, () =>
                materialPriceListQR.refetch().then(data => {
                  setMaterialData(data.data.supplierMaterialPriceLists)
                })
              )}
              data={materialData}
              getRowId={row => row.id}
              editDisplayMode="cell"
              enableEditing={true}
              enableRowActions={true}
              enableBottomToolbar={true}
              enablePagination={true}
              enableTopToolbar={true}
              initialState={{
                density: 'compact',
                sorting: [{ id: 'material', desc: false }],
                pagination: { pageIndex: 0, pageSize: 50 }
              }}
              renderTopToolbarCustomActions={({ table }) => (
                <Button
                  variant="contained"
                  onClick={() => {
                    addPriceListHandler()
                  }}
                >
                  Přidat materiál
                </Button>
              )}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => showRowEditor(row)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => deleteMaterialPriceListHandler(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          </MuiScopedContainer>

          <hr />
          <h2>Dodavatelé, kteří vozí na tuto koncovku</h2>
          <MuiScopedContainer>
            <MaterialReactTable
              columns={listOfDependenciesColumns}
              data={listOfDependencies4MaterialQR.data?.listOfDependencies ? listOfDependencies4MaterialQR.data?.listOfDependencies : []}
              getRowId={row => row.id}
              editDisplayMode="row"
              enableBottomToolbar={false}
              enableTopToolbar={false}
              enableRowActions={true}
              initialState={{
                density: 'compact',
                sorting: [{ id: 'Dodavatel', desc: false }]
              }}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <a
                    href={`/configuration/supplier/transport-of-material?supplierId=${row.original?.supplier?.id}&supplierBranchId=${row.id}`}
                    target={'_blank'}
                  >
                    <IoNavigateOutline />
                  </a>
                </Box>
              )}
            />
          </MuiScopedContainer>
        </>
      )}
    </PageCard>
  )

  function onModalClose() {
    materialPriceListQR.refetch().then(data => {
      setMaterialData(data.data.supplierMaterialPriceLists)
    })
    hideModal()
  }
  function addPriceListHandler() {
    showModal({
      title: 'Přidat ceník',
      modalContent: (
        <SupplierEditMaterialPrice
          branchServiceId={currentServiceSetting?.id}
          onClose={onModalClose}
          doNotShowMaterials={materialPriceListQR.data?.supplierMaterialPriceLists.map(x => x.productMaterialId) ?? []}
        />
      ),
      hideFooter: true
    })
  }

  function showRowEditor(row: MRT_Row<SupplierMaterialPriceListEntity>) {
    const item = materialPriceListQR.data?.supplierMaterialPriceLists.find(x => x.id === row.id)
    showModal({
      title: 'Editovat ceník',
      modalContent: <SupplierEditMaterialPrice branchServiceId={currentServiceSetting?.id} onClose={onModalClose} editItem={item} doNotShowMaterials={[]} />,
      hideFooter: true
    })
  }

  function deleteMaterialPriceListHandler(row: MRT_Row<SupplierMaterialPriceListEntity>) {
    const promise = deleteMaterialPriceListMut({ variables: { id: row.id } })
    return processPageOperation({
      promise: promise,
      successMessage: 'Ceník byl smazán',
      successAction: () =>
        materialPriceListQR.refetch().then(data => {
          setMaterialData(data.data.supplierMaterialPriceLists)
        })
    })
  }
}
