import React, { useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import SelectField from '../../../../components/formComponents/SelectField'
import ToggleField from '../../../../components/formComponents/ToggleField'
import FormField, { inputType } from '../../../../components/formComponents/FormField'

import LoadingContainer from '../../../../components/LoadingContainer'

import { processPageOperation } from '../../../../services/formsServices/pageOperationProcessor'
import { isAnyQueryLoading } from '../../../../services/functions/queryHelpers'
import { useUpsertSupplierMaterialPriceListMutation } from './mutations/upsert-supplier-material-price-list.mutation'
import { useProductMaterialListQuery } from '../../../ProductMaterialList/queries/product-material-list.query'
import { SupplierMaterialPriceListEntity } from '../../../../graphql/aminTypes'
import { WarningAlert } from '../../../../components/formComponents/alert'

export default function SupplierEditMaterialPrice({
  branchServiceId,
  onClose,
  editItem,
  doNotShowMaterials
}: {
  editItem?: SupplierMaterialPriceListEntity
  branchServiceId?: string
  onClose: () => void
  doNotShowMaterials: string[]
}) {
  const [upsertSupplierMaterialPriceListMut, upsertSupplierMaterialPriceListQR] = useUpsertSupplierMaterialPriceListMutation()
  const productMaterialListQR = useProductMaterialListQuery()
  const materialRef: React.RefObject<HTMLInputElement | null | undefined> = useRef()

  const formMethods = useForm<SupplierMaterialPriceListEntity>({
    defaultValues: {
      isActive: true,
      priceKc: 0,
      productMaterialId: '',
      pricePerCubicKc: undefined
    }
  })

  const availableMaterials =
    productMaterialListQR.data?.productMaterialList.filter(x => !doNotShowMaterials.includes(x.id)).map(x => ({ value: x.id, label: x.name })) || []

  useEffect(() => {
    if (editItem && productMaterialListQR.data) {
      formMethods.reset({
        isActive: editItem.isActive,
        priceKc: editItem.priceKc,
        pricePerCubicKc: editItem.pricePerCubicKc,
        productMaterialId: editItem.productMaterialId
      })
    }
  }, [editItem, productMaterialListQR.data])

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(upsertSupplierMaterialPriceListQR, productMaterialListQR)}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(updateMaterialPriceList)} autoComplete={'off'}>
          <div className="row mb-2">
            <div className="col-md-2">
              <ToggleField name={'isActive'} label={'Aktivní'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-6">
              <SelectField name={'productMaterialId'} label={'Materiál'} optionsData={availableMaterials} disabled={!!editItem} forwardRef={materialRef} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <FormField type={inputType.number} name={'priceKc'} label={'Cena za 1t'} valueAsNumber />
            </div>
            <div className="col-md-4">
              <FormField type={inputType.number} name={'pricePerCubicKc'} label={'Cena za 1m³'} valueAsNumber />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <WarningAlert message={'Pokud je zadána cena za 1m³, cena za tunu se automaticky přepíše výpočtem.'} />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-2 offset-6">
              <button type="button" className="btn btn-secondary btn-block" onClick={() => onClose()}>
                Zavřít bez uložení
              </button>
            </div>
            <div className="col-md-2">
              <button type="button" className="btn btn-primary btn-block" onClick={() => upsertAndClose()}>
                Uložit a zavřít
              </button>
            </div>
            <div className="col-md-2">
              {!editItem && (
                <button type="submit" className="btn btn-primary btn-block">
                  Uložit a přidat další
                </button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </LoadingContainer>
  )

  function upsertAndClose() {
    updateMaterialPriceList(formMethods.getValues())
    onClose()
  }
  function updateMaterialPriceList(data: any) {
    const variables = {
      branchServiceId: branchServiceId,
      data: {
        isActive: data.isActive,
        priceKc: data.priceKc,
        pricePerCubicKc: data.pricePerCubicKc && data.pricePerCubicKc > 0 ? data.pricePerCubicKc : null,
        productMaterialId: data.productMaterialId
      },
      materialPriceListId: editItem?.id
    }
    const promise = upsertSupplierMaterialPriceListMut({ variables })
    return processPageOperation({
      promise: promise,
      successMessage: 'Ceník byl uložen',
      successAction: () => {
        doNotShowMaterials.push(data.productMaterialId)
        if (materialRef.current) materialRef.current.focus()
        formMethods.reset({
          isActive: true,
          priceKc: 0,
          pricePerCubicKc: 0,
          productMaterialId: undefined
        })
      }
    })
  }
}
