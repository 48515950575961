import { gql, useQuery } from '@apollo/client'
import { DemandChangeLogEntity } from '../../../../../graphql/aminTypes'

export const getOrderHistoryQuery = gql`
  query getOrderHistory($orderContentId: Int!) {
    getOrderHistory(orderContentId: $orderContentId) {
      id
      createdAt
      login {
        id
        firstname
        lastname
      }
      newState {
        id
        name
        sysName
      }
      operation
      supplier {
        id
        name
      }
    }
  }
`

export function useOrderHistoryQuery(orderContentId?: number) {
  return useQuery<{ getOrderHistory: DemandChangeLogEntity[] }>(getOrderHistoryQuery, {
    skip: !orderContentId,
    fetchPolicy: 'network-only',
    variables: { orderContentId: orderContentId }
  })
}
