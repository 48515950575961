import { gql, useQuery } from '@apollo/client'
import { SupplierTruckEntity } from '../../../../graphql/aminTypes'

export const supplierServiceLoadTransportQuery = gql`
  query supplierServiceLoadTransport($branchServiceId: ID!, $supportedCollectionService: WasteCategory, $supportedDeliveryService: TransportCategory) {
    supplierServiceLoadTransport(
      branchServiceId: $branchServiceId
      supportedCollectionService: $supportedCollectionService
      supportedDeliveryService: $supportedDeliveryService
    ) {
      id
      isActive
      capacityTonne
      name
      truckType
      minimumPriceOfTransportKc {
        price
        serviceCategory
      }
    }
  }
`

export function useSupplierServiceLoadTransportForSolidWaste(variables: any) {
  return useQuery<{ supplierServiceLoadTransport: SupplierTruckEntity[] }>(supplierServiceLoadTransportQuery, {
    fetchPolicy: 'network-only',
    variables,
    skip: !variables.branchServiceId
  })
}
