import { gql, useMutation } from '@apollo/client'
import { IMutationResult } from '../../../types/gqlTypes'
import { contextEndpoint, EndpointEnum } from '../../../appConfiguration/contextEndpoint'

const syncAccountingDocumentMutation = gql`
  mutation syncAccountingDocument($accountingDocumentId: ID, $groupAccountingDocumentId: ID) {
    syncAccountingDocument(accountingDocumentId: $accountingDocumentId, groupAccountingDocumentId: $groupAccountingDocumentId) {
      status {
        reason
        result
      }
    }
  }
`

export function useSyncAccountingDocument(refetchQueries: string[] = ['accountingDocuments']) {
  return useMutation<{ syncAccountingDocument: IMutationResult }>(syncAccountingDocumentMutation, {
    context: contextEndpoint(EndpointEnum.admin),
    refetchQueries
  })
}
