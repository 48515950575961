import { gql, useQuery } from '@apollo/client'
import { GetAvailableServicesForLocalityResponse, PriceCalculatorResponse, TransportCategory } from '../../../graphql/aminTypes'
import { contextEndpoint, EndpointEnum } from '../../../services/appConfiguration/contextEndpoint'
import { WasteCategoryType } from '../../../services/types/waste-category'

export const calculateDeliveryServiceOptionsQuery = gql`
  query calculateDeliveryServiceOptions(
    $customerLatLng: LatLngInput!
    $radiusKm: Int!
    $calculationDate: String!
    $routingStrategy: String!
    $materialId: ID!
    $transportCategory: TransportCategory!
    $volumeCubic: Float!
    $weightTonne: Float!
    $leaveContainerOnPlace: Boolean!
    $containerRentLengthDays: Int
  ) {
    calculateDeliveryServiceOptions(
      customerLatLng: $customerLatLng
      radiusKm: $radiusKm
      calculationDate: $calculationDate
      routingStrategy: $routingStrategy
      containerRentLengthDays: $containerRentLengthDays
      leaveContainerOnPlace: $leaveContainerOnPlace
      materialId: $materialId
      transportCategory: $transportCategory
      volumeCubic: $volumeCubic
      weightTonne: $weightTonne
    ) {
      supplierBranch {
        id
        address {
          lng
          lat
          city
        }
        supplier {
          id
          name
        }
      }
      calculationOptions {
        distanceKm
        encodedGeometry
        container {
          id
          name
          volumeM3
        }
        truck {
          id
          name
          capacityTonne
        }
        priceBreakdown {
          amount
          note
          marginDecimal
          priceItemType {
            id
            name
          }
        }
        priceInclVatKc
        priceWoVatKc
        type
        thirdPartySupplierBranch {
          id
          specification
          address {
            lng
            lat
            city
          }
          supplier {
            id
            name
          }
        }
      }
    }
  }
`

export function useCalculateDeliveryServiceOptionsQuery() {
  return useQuery<{ calculateDeliveryServiceOptions: PriceCalculatorResponse[] }>(calculateDeliveryServiceOptionsQuery, {
    fetchPolicy: 'network-only',
    skip: true,
    context: contextEndpoint(EndpointEnum.admin)
  })
}

export function createCalculateDeliveryServiceOptionsVariables(data: any, transportCategory: TransportCategory) {
  return {
    customerLatLng: { lat: parseFloat(data.customerAddress['lat']), lng: parseFloat(data.customerAddress['lng']) },
    radiusKm: data.radiusKm,
    calculationDate: data.calculationDate,
    routingStrategy: data.routingStrategy,
    containerRentLengthDays: data.containerRentLengthDays,
    leaveContainerOnPlace: data.leaveContainerOnPlace,
    materialId: data.materialId,
    volumeCubic: data.volumeCubic,
    weightTonne: data.weightTonne,
    transportCategory: transportCategory
  }
}
