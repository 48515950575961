import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../services/bootstrapTable/services/tableSpecificationTypes'
import { SupplierTruckEntity } from '../../../graphql/aminTypes'
import { moneyToLocaleString } from '../../../services/dataToString/decimalToLocaleString'
import { supportedDeliveryServicesMap } from '../const/supported-delivery-services.map'
import { supportedCollectionServicesMap } from '../const/supported-collection-services.map'
import { truckTypeTranslation } from '../components/SupplierTruck/truck-type'
import { serviceCategoryTranslation } from '../../../services/types/service-category.type'

export const supplierServiceLoadTransportDataDefinition: TableDefinitionTypes<SupplierTruckEntity> = {
  columnDefinition: {
    id: { hidden: true },
    isActive: {
      name: 'Aktivní',
      columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter,
      align: ColumnAlign.center,
      headerStyle: { width: '80px' }
    },
    name: { name: 'Název' },
    truckType: { name: 'Typ vozidla' },
    collectionTypes: { name: 'Odvoz' },
    deliveryTypes: { name: 'Rozvoz' },
    capacityTonne: { name: 'Doložnost [t]' },
    pricePerKmKc: { name: 'Cena za km' },
    minimumPriceOfTransport: { name: 'Minimální ceny za dopravu' },
    hasHydraulicArm: { name: 'Hydraulická tuka', align: ColumnAlign.center, columnFormatterType: ColumnFormatterTypes.yesNoOptionalFormatter },
    actions: { name: 'Akce', columnFormatterType: ColumnFormatterTypes.actionsObject, align: ColumnAlign.center, headerStyle: { width: '80px' } }
  },
  sourceToColumnMapping: {
    id: x => x.id,
    isActive: x => x.isActive,
    name: x => x.name,
    truckType: x => truckTypeTranslation[x.truckType],
    collectionTypes: x =>
      x.supportedCollectionServices.map(y => (
        <>
          {supportedCollectionServicesMap[y]} <br />
        </>
      )),
    deliveryTypes: x =>
      x.supportedDeliveryServices.map(y => (
        <>
          {supportedDeliveryServicesMap[y]} <br />
        </>
      )),
    capacityTonne: x => x.capacityTonne,
    pricePerKmKc: x => moneyToLocaleString(x.pricePerKmKc),
    minimumPriceOfTransport: x => x.minimumPriceOfTransportKc?.map(y => `${y.price} Kč (${serviceCategoryTranslation[y.serviceCategory]})`).join(', '),
    hasHydraulicArm: x => x.hasHydraulicArm,
    actions: x => {
      const ret: IRowAction[] = []
      ret.push({
        type: RowActionType.codeAction,
        value: 'deleteTruck',
        icon: 'fe-x',
        title: 'Odstranit auto'
      })
      return ret
    }
  }
}
