import { gql, useQuery } from '@apollo/client'
import { IGqlPagingQueryParams } from '../../../services/bootstrapTable/usePagingFromURLQuery'
import { DependentBranchesResponse, SupplierBranchEntity, SupplierSupplierListResponse } from '../../../graphql/aminTypes'
import { ServiceCategoryType } from '../../../services/types/service-category.type'

export const getDependentBranchesQuery = gql`
  query getDependentBranches($branchId: ID!) {
    getDependentBranches(branchId: $branchId) {
      suppliersWhoUsesMeAs3rdPartySupplier {
        id
        address {
          lat
          lng
        }
      }
      suppliersWhoUsesMeAsMainSupplier {
        id
        address {
          lat
          lng
        }
      }
    }
  }
`

export function useGetDependentBranchesQuery(branch: SupplierBranchEntity | null, showDependencies: boolean) {
  return useQuery<{ getDependentBranches: DependentBranchesResponse }>(getDependentBranchesQuery, {
    fetchPolicy: 'network-only',
    variables: { branchId: branch?.id },
    skip: !branch || !showDependencies
  })
}
