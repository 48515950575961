import { MaterialReactTable } from 'material-react-table'
import LoadingContainer from '../../components/LoadingContainer'
import { MuiScopedContainer } from '../../components/mui-react-table/MuiScopedContainer'
import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import useProductMaterialListTableDefinition from './services/product-material-list-data-definition'
import { useContext } from 'react'
import { useProductMaterialListQuery } from './queries/product-material-list.query'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import MaterialTopToolbarCustomActions from './components/MaterialTopToolbarCustomActions'
import EditIcon from '@mui/icons-material/Edit'
import AddOrEditMaterial from './components/AddOrEditMaterial/AddOrEditMaterial'
import { ModalContext } from '../../components/Modal/ModalProvider'
import { useDeleteProductMaterialMutation } from './mutations/delete-product-material.mutation'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import { useDeleteDialog } from '../../components/DeleteDialog/DeleteDialog'
import { hideTopToolbarDefaultActions, hideTopToolbarDefaultActionsWithFilters } from '../../components/mui-react-table/hide-top-toolbar-default-actions'

const ProductMaterialList = () => {
  const columnDefinition = useProductMaterialListTableDefinition()
  const productMaterialListQR = useProductMaterialListQuery()
  const { showModal, hideModal } = useContext(ModalContext)
  const [deleteProductMaterialMut, deleteProductMaterialQR] = useDeleteProductMaterialMutation()
  const [DeleteDialog, deleteDialogCtrl] = useDeleteDialog()

  const handleEditRow = (row: any) => {
    if (!row.id) return
    const material = productMaterialListQR.data?.productMaterialList.find(x => x.id === row.id)
    showModal({
      title: 'Upravit materiál ' + row.original.name,
      hideFooter: true,
      modalContent: <AddOrEditMaterial materialToEdit={material} onExit={hideModal} />
    })
  }

  const deleteProductMaterialHandler = (row: any) => {
    const promise = deleteProductMaterialMut({ variables: { id: row.id } })
    processPageOperation({
      promise,
      successMessage: 'Materiál byl odstraněn'
    })
  }

  return (
    <LoadingContainer showLoader={isAnyQueryLoading(productMaterialListQR, deleteProductMaterialQR)}>
      <MuiScopedContainer>
        <MaterialReactTable
          columns={columnDefinition}
          data={productMaterialListQR.data?.productMaterialList ?? []}
          getRowId={row => row.id}
          enableEditing={true}
          enablePagination={false}
          createDisplayMode="row"
          editDisplayMode="row"
          enableBottomToolbar={false}
          enableTopToolbar={true}
          {...hideTopToolbarDefaultActionsWithFilters}
          renderTopToolbarCustomActions={() => <MaterialTopToolbarCustomActions />}
          state={{
            density: 'compact',
            sorting: [{ id: 'validFrom', desc: false }]
          }}
          defaultColumn={{
            muiTableHeadCellProps: {
              style: { backgroundColor: '#fcce5b' }
            }
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
              <Tooltip title="Upravit materiál">
                <IconButton sx={{ color: '#206BC4' }} onClick={() => handleEditRow(row)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Odstranit odpad">
                <IconButton
                  color="error"
                  onClick={() => {
                    deleteDialogCtrl.showDialog(row.original.name, () => deleteProductMaterialHandler(row))
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        {DeleteDialog()}
      </MuiScopedContainer>
    </LoadingContainer>
  )
}

export default ProductMaterialList
