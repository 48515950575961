import { IOrderContentListItem, useOrderListQuery } from './queries/order-list.query'
import PageCard from '../../components/Cards/pageCard'
import React, { useContext, useMemo, useState } from 'react'
import useBootstrapTableDataMapper from '../../services/bootstrapTable/useBootstrapTableDataMapper'
import { orderListDataDefinition } from './services/orderListDataDefinition'
import { isAnyQueryLoading } from '../../services/functions/queryHelpers'
import BootstrapTable from 'react-bootstrap-table-next'
import { processPageOperation } from '../../services/formsServices/pageOperationProcessor'
import usePagingFromURLQuery from '../../services/bootstrapTable/usePagingFromURLQuery'
import useBootstrapTablePagingAndFiltration from '../../services/bootstrapTable/useBootstrapTablePagingAndFiltration'
// @ts-ignore
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import { selectRow } from './services/orderListSettings'
import StatsButtonRow from './components/StatsButtonRow'
import { createParamsForPerformStateActionMutation, performStateActionEnum, usePerformStateActionMutation } from './services/usePerformStateActionMutation'
import { useOrderContentStatesStatQuery } from './queries/order-content-states-stat.query'
import OrderListExpand from './components/OrderListExpand'
import { ModalContext } from '../../components/Modal/ModalProvider'
import SelectField from '../../components/formComponents/SelectField'
import { FormProvider, useForm } from 'react-hook-form'
import { AdminActionList } from './services/AdminActionList'
import { ChangeOrderState } from './components/ChangeOrderState'
import { createParamsForRunOrderOperationMutation, runOrderOperationEnum, useRunOrderOperationMutation } from './services/runOrderOperationMutation'
import { updateFieldEnum, useUpdateFieldMutation } from './services/updateFieldMutation'
import { useGetContainerFromToDates } from './queries/container-from-to-dates.query'
import { BootstrapTablePagingWrapper } from '../../components/bootstrapTable/BootstrapTablePagingWrapper'
import { useHighlightRowAfterReturn } from '../../services/pageHooks/highlightRowAfterReturn'
import { httpBuildQueryString, removeParamFromSearchString } from '../../services/functions/httpBuildQuery'
import { useNavigate } from 'react-router-dom'
import { usePrepareFilterOptions } from './services/usePrepareFilterOptions'
import highlightOrders from './services/highlightOrders'
import { getParamFromUrl } from '../../services/functions/getParamFromUrl'
import { createParamsForPerformGroupActionMutation, usePerformGroupChangeMutation } from './services/usePerformGroupActionMutation'
import { useProducts } from '../OrderDetail/queries/productsQuery'
import { useDownloadExport } from '../../hooks/useDownloadExport'
import { useAdminOwnersQuery } from './queries/admin-owners.query'
import { Login } from '../../graphql/aminTypes'
import { SelectedTotals } from './components/SelectedTotals'
import { serviceTypeSelectOptions } from '../../services/types/service-type'
import AdditionalSearchRow from './components/AdditionalSearchRow'
import useIsMobile from '../../services/hooks/is-mobile'

export default function OrderList() {
  const { showModal, hideModal } = useContext(ModalContext)
  const isMobile = useIsMobile()
  const [selectedTotals, setSelectedTotals] = useState({ woVat: 0, withVat: 0 })
  const navigate = useNavigate()
  const download = useDownloadExport()
  const adminOwnersQR = useAdminOwnersQuery()
  const formMethods = useForm()
  const containerFromToDatesQR = useGetContainerFromToDates()
  const { createReturnUrl, moveScrollPosition, rowClassForHighlightedRow } = useHighlightRowAfterReturn()
  const ordersForCustomer = useMemo(() => (getParamFromUrl('customerName') ? getParamFromUrl('customerName') : undefined), [])
  const allProducts = useProducts()
  const [rowToExpand, setRowToExpand] = useState<number>()
  const showSearchState = useState<boolean>(false)
  const { generateColumnListForBootstrapTable, mapDataArrayToTable, nameForGqlTranslation, defaultSort } = useBootstrapTableDataMapper<IOrderContentListItem>(
    orderListDataDefinition(
      allProducts,
      toggleCertificateDone,
      toggleInvoicingNoteDone,
      containerFromToDatesQR?.data?.getContainerFromToDates.fromDates,
      containerFromToDatesQR?.data?.getContainerFromToDates.toDates,
      isMobile
    )
  )

  const { pageConfiguration, gqlQueryParams } = usePagingFromURLQuery(
    [
      'orderNumber',
      'b2bCustomerId',
      'adminOwner',
      'name',
      'state',
      'supplier',
      'flags',
      'containerFromDateFrom',
      'containerToDateFrom',
      'containerFromDateTo',
      'containerToDateTo',
      'createdAt',
      'createdAtFrom',
      'createdAtTo',
      'realisedAtFrom',
      'realisedAtTo',
      'city',
      'container',
      'product',
      'serviceType',
      'paymentType',
      'paymentStatus',
      'partnerSource',
      'emptyToDate'
    ],
    nameForGqlTranslation,
    { defaultSort, defaultPageSize: 50 }
  )
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set())

  const orderContentStatesStatQR = useOrderContentStatesStatQuery()
  const orderListQR = useOrderListQuery(gqlQueryParams, moveScrollPosition)
  const [performStateActionMut, performStateActionQR] = usePerformStateActionMutation()
  const [performGroupActionMut, performGroupActionQR] = usePerformGroupChangeMutation()
  const [runOrderOperationMut, runOrderOperationQR] = useRunOrderOperationMutation()
  const [updateFieldMut, updateFieldQR] = useUpdateFieldMutation()
  const { bootstrapTablePaginationSetup, handleTableChange } = useBootstrapTablePagingAndFiltration(
    pageConfiguration,
    undefined,
    undefined,
    orderListQR.data?.orderContentList.total
  )

  const { filterOptions } = usePrepareFilterOptions()
  filterOptions.addFilterOption(
    'serviceType',
    serviceTypeSelectOptions,
    (item: any) => item.value,
    (item: any) => item.label
  )
  if (adminOwnersQR.data?.adminOwners)
    filterOptions.addFilterOption(
      'adminOwner',
      adminOwnersQR.data?.adminOwners,
      (item: Login) => item.id,
      (item: Login) => `${item.firstname} ${item.lastname}`
    )

  const columnDefinition = generateColumnListForBootstrapTable({
    columnAction: handleTableClick,
    filterDefaultValues: pageConfiguration.filterBy,
    currentSort: pageConfiguration.sort,
    handleTableChange: handleTableChange,
    filterOptions4CustomSelectCreator: filterOptions,
    actionsReference: {
      editOrder: row => {
        const fwQueryString = httpBuildQueryString({
          orderNumber: row.orderNumber,
          returnUrl: createReturnUrl(row.id)
        })
        navigate('/edit-order' + fwQueryString)
      },
      approveByAdmin: async row =>
        await performActionHandler(
          [row.id],
          `Opravdu předat na dodavatele objednávku ${row.orderNumber}?` +
            (!row.supplierEmail ? ' Dodavatel nemá nastavený žádný email a nepřijde mu notifikace!' : ''),
          `Objednávka ${row.orderNumber} byla předána na dodavatele.` + (!row.supplierEmail ? ' Kontaktujte dodavatele telefonicky!' : ''),
          performStateActionEnum.APPROVE
        ),
      undoApproveByAdmin: async row =>
        await performActionHandler(
          [row.id],
          `Opravdu přepnout zpět do stavu "Ke schválení administrátorem" objednávky ${row.orderNumber}?`,
          `Objednávka ${row.orderNumber} byla dodavateli odebrána.`,
          performStateActionEnum.UNDO_APPROVE
        ),
      cancelOrder: async row => {
        const reason = window.prompt(`Opravdu zrušit objednávku ${row.orderNumber}? Můžete zadat důvod: `)
        if (reason === null) return
        await appendAdminNote(row.id, reason)
        await performActionHandler([row.id], undefined, `Objednávka ${row.orderNumber} byla zrušena.`, performStateActionEnum.CANCEL_BY_ADMIN)
      },
      orderChargedByAdmin: async row =>
        await performActionHandler(
          [row.id],
          `Označit objednávku jako zaúčtovanou ${row.orderNumber}?`,
          `Objednávka ${row.orderNumber} byla označena jako zaúčtována.`,
          performStateActionEnum.ORDER_CHARGED_BY_ADMIN
        ),
      skipPayment: async row =>
        await performActionHandler(
          [row.id],
          `Nečekat na zaplacení objednávky ${row.orderNumber}?`,
          `Objednávka přešla do stavu čeká na schválení administrátorem i bez zaplacení.`,
          performStateActionEnum.SKIP_PAYMENT
        )
    }
  })

  let ordersData: any[] = []
  if (orderListQR.data && orderListQR.data.orderContentList?.items) ordersData = mapDataArrayToTable(orderListQR.data.orderContentList.items)

  return (
    <PageCard
      showLoader={isAnyQueryLoading(
        orderListQR,
        performStateActionQR,
        updateFieldQR,
        runOrderOperationQR,
        containerFromToDatesQR,
        performGroupActionQR,
        adminOwnersQR
      )}
      headerTitle={`Seznam zakázek${ordersForCustomer ? ' pro zákazníka ' + ordersForCustomer : ''}`}
      cardOptions={
        <button
          type="button"
          className="btn btn-sm"
          onClick={() => download(`/api/export-orders?${removeParamFromSearchString(httpBuildQueryString(gqlQueryParams), ['offset', 'limit']).join('&')}`)}
        >
          Export
        </button>
      }
    >
      {!isMobile && (
        <>
          <StatsButtonRow
            showSearchState={showSearchState}
            orderContentStatesStat={orderContentStatesStatQR.data?.orderContentStatesStat ?? []}
            setSelectedRows={setSelectedRows}
          />
          <AdditionalSearchRow showSearchState={showSearchState} />
        </>
      )}
      <PaginationProvider pagination={paginationFactory(bootstrapTablePaginationSetup)}>
        {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => (
          <div className="table-responsive-xl">
            <BootstrapTablePagingWrapper
              paginationProps={paginationProps}
              paginationTableProps={paginationTableProps}
              middleColumnHtml={<SelectedTotals totals={selectedTotals} />}
            >
              <BootstrapTable
                bootstrap4
                striped
                hover
                condensed
                remote
                classes="table-responsive-lg digiTable"
                noDataIndication={() => <>"Pro vybrané filtry nejsou k dispozici žádné objednávky"</>}
                keyField="id"
                selectRow={selectRow(handleOnSelect, handleOnSelectAll, Array.from(selectedRows))}
                data={ordersData}
                columns={columnDefinition}
                rowClasses={rowClassForHighlightedRow(highlightOrders)}
                filter={filterFactory()}
                filterPosition={'inline'}
                sort={pageConfiguration.sort}
                expandRow={orderRowExpand(rowToExpand)}
                onTableChange={handleTableChangeWrapper}
                {...paginationTableProps}
              />
            </BootstrapTablePagingWrapper>
          </div>
        )}
      </PaginationProvider>

      <div className="row mt-3">
        <div className="col-md-9">
          <div className="d-flex flex-row flex-wrap justify-content-md-around">
            <button
              type="button"
              disabled={selectedRows.size === 0}
              className="btn bg-warning btn-block m-1"
              onClick={async () => {
                checkForNotEmailSupportedSuppliers(selectedRows)
                await performActionHandler(
                  Array.from(selectedRows),
                  `Schválit ${selectedRows.size} objednávek?`,
                  'Vybrané objednávky schváleny',
                  performStateActionEnum.APPROVE
                )
              }}
            >
              <i className="fe fe-truck p-1" />
              Schválit vybrané objednávky
            </button>
            <button
              type="button"
              disabled={selectedRows.size === 0}
              className="btn bg-lime btn-block m-1"
              onClick={async () =>
                await performActionHandler(
                  Array.from(selectedRows),
                  `Zaúčtovat ${selectedRows.size} objednávek?`,
                  'Vybrané objednávky zaúčtovány',
                  performStateActionEnum.ORDER_CHARGED_BY_ADMIN
                )
              }
            >
              <i className="fe fe-dollar-sign p-1" />
              Zaúčtovat vybrané objednávky
            </button>
            <button
              type="button"
              disabled={selectedRows.size === 0}
              className="btn bg-azure btn-block m-1"
              onClick={async () => await setGroupInvoiceHandler(Array.from(selectedRows))}
            >
              <i className="fe fe-layers p-1" />
              Uhradit hromadnou fakturou
            </button>
            <button
              type="button"
              disabled={selectedRows.size === 0}
              className="btn bg-blue-lt btn-block m-1"
              onClick={async () => await takeGroupOwnershipHandler(Array.from(selectedRows))}
            >
              <i className="fe fe-user-check p-1" />
              Převzít vybrané objednávky
            </button>
            <button
              type="button"
              disabled={selectedRows.size === 0}
              className="btn bg-yellow-lt btn-block m-1"
              onClick={async () => await markCertificateDoneHandler(Array.from(selectedRows))}
            >
              <i className={`mr-2 fe icon-file-certificate--green`}></i>
              Označit certifikát jako hotový
            </button>
            <div style={{ width: '300px' }}>
              <FormProvider {...formMethods}>
                <SelectField optionsData={AdminActionList} name={'AdminAction'} />
                <button
                  type="button"
                  disabled={selectedRows.size === 0}
                  className="btn btn-block bg-red text-white m-1"
                  onClick={async () =>
                    await performActionHandler(
                      Array.from(selectedRows),
                      `Změnit stav u ${selectedRows.size} objednávek? Budou edeslány emaily, stejně jako kdyby tuto akci zvolil dodavatel.`,
                      'Vybrané objednávky byly aktualizovány',
                      formMethods.watch('AdminAction')
                    )
                  }
                >
                  <i className="fe fe-zap p-1" />
                  Provést vybranou akci
                </button>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </PageCard>
  )

  function checkForNotEmailSupportedSuppliers(selectedRows: Set<number>) {
    const suppliersWoEmail = orderListQR.data?.orderContentList.items.filter(x => selectedRows.has(x.id)).filter(x => !x.supplier.email)
    if (suppliersWoEmail && suppliersWoEmail.length > 0)
      window.alert('Někteří dodavatelé nemají zadaný email a nedostanou notifikaci. Kontaktujte je telefonicky.')
  }

  function handleTableClick(e: Event, column: number, columnIndex: number, row: any) {
    if (columnIndex === 10 || columnIndex === 13) return
    if ((columnIndex === 2 && !isMobile) || (columnIndex === 3 && isMobile)) {
      window.open(`https://${process.env.REACT_APP_FE_HOSTNAME}/objednavka?uuid=${row['uuid']}`, '_blank')
    } else setRowToExpand(rowToExpand === row.id ? undefined : row.id)
  }

  function handleTableChangeWrapper(type: TableChangeType, { filters, page, sizePerPage, sortField, sortOrder }: TableChangeNewState) {
    setSelectedRows(new Set())
    handleTableChange(type, { filters, page, sizePerPage, sortOrder, sortField })
  }

  async function performActionHandler(id: number[], confirmMessage: string | undefined, successMessage: string, action: performStateActionEnum) {
    if (id.length <= 0) return
    if (!confirmMessage || window.confirm(confirmMessage)) {
      const promise = performStateActionMut({ variables: createParamsForPerformStateActionMutation(id, action) })
      return processPageOperation({
        promise: promise,
        successMessage,
        failedAction: refetchAll,
        successAction: refetchAll
      })
    }
  }

  async function setGroupInvoiceHandler(id: number[]) {
    if (id.length <= 0) return
    const webUrl = prompt('Zadejte odkaz na detail faktury ve tvaru "https://app.vyfakturuj.cz/faktury/detail/xxxxxxx/"')
    if (!webUrl) return
    const promise = performGroupActionMut({ variables: createParamsForPerformGroupActionMutation(id, 'SET_GROUP_INVOICE', webUrl) })
    return processPageOperation({
      promise: promise,
      successMessage: 'Hromadná faktura byla úspěšně nastavena pro vybrané objednávky',
      failedAction: refetchAll,
      successAction: refetchAll
    })
  }

  async function takeGroupOwnershipHandler(id: number[]) {
    if (id.length <= 0) return
    const promise = performGroupActionMut({ variables: createParamsForPerformGroupActionMutation(id, 'TAKE_OWNERSHIP') })
    return processPageOperation({
      promise: promise,
      successMessage: 'Označené zakázky jsou nyní přiřazeny vám',
      failedAction: refetchAll,
      successAction: refetchAll
    })
  }
  async function markCertificateDoneHandler(id: number[]) {
    if (id.length <= 0) return
    if (!window.confirm('Opravdu chcete u vybraných objednávek nastavit stav certifikátu jako hotový?')) return
    const promise = performGroupActionMut({ variables: createParamsForPerformGroupActionMutation(id, 'MARK_CERTIFICATE_DONE') })
    return processPageOperation({
      promise: promise,
      successMessage: 'Označené zakázky mají označen certifikát jako hotový',
      failedAction: refetchAll,
      successAction: refetchAll
    })
  }

  async function appendAdminNote(orderContentId: number, reason: string) {
    if (!orderListQR?.data?.orderContentList) return
    const currentRow = orderListQR.data.orderContentList.items.find(x => x.id === orderContentId)
    if (!currentRow) return
    reason = 'Důvod zrušení: ' + reason

    const promise = updateFieldMut({
      variables: {
        orderContentId: currentRow.id,
        field: updateFieldEnum.ADMIN_NOTE,
        newValue: currentRow.adminNote ? `${currentRow.adminNote} ${reason}` : reason
      }
    })
    return processPageOperation({ promise: promise })
  }

  function handleOnSelect(row: any, isSelect: boolean) {
    if (isSelect) {
      setSelectedRows(new Set(selectedRows).add(row.id))
      setSelectedTotals(prevState => ({
        withVat: prevState.withVat + row.totalInclVatForSum,
        woVat: prevState.woVat + row.totalWoVatForSum
      }))
    } else {
      selectedRows.delete(row.id)
      setSelectedRows(new Set(selectedRows))
      if (selectedRows.size === 0) setSelectedTotals({ withVat: 0, woVat: 0 })
      else
        setSelectedTotals(prevState => ({
          withVat: prevState.withVat - row.totalInclVatForSum,
          woVat: prevState.woVat - row.totalWoVatForSum
        }))
    }
    return true
  }

  function toggleCertificateDone(orderId: number, currentState: number) {
    return () => {
      if (window.confirm(`Chcete změnit stav certifikátu?`) !== true) return
      if (currentState === 0) return
      const promise = runOrderOperationMut({
        variables: createParamsForRunOrderOperationMutation(
          orderId,
          currentState === 1 ? runOrderOperationEnum.CERTIFICATE_DONE : runOrderOperationEnum.UNDO_CERTIFICATE_DONE
        )
      })
      processPageOperation({
        promise,
        successMessage: 'Stav vygenerování certifikátu změněn',
        successAction: refetchAll
      })
    }
  }

  function toggleInvoicingNoteDone(orderId: number, currentState?: boolean) {
    return () => {
      if (window.confirm(`Chcete změnit stav účetní poznámky?`) !== true) return
      const promise = runOrderOperationMut({
        variables: createParamsForRunOrderOperationMutation(
          orderId,
          currentState === true ? runOrderOperationEnum.INVOICING_NOTE_DONE_FALSE : runOrderOperationEnum.INVOICING_NOTE_DONE_TRUE
        )
      })
      processPageOperation({
        promise,
        successMessage: 'Stav zpracování poznámky k fakturaci upraven',
        successAction: refetchAll
      })
    }
  }

  function changeOrderStateHandler(row: any) {
    if (!row.id) return
    showModal({
      title: `Změna stavu objednávky ${row.orderNumber}`,
      modalContent: (
        <ChangeOrderState
          orderContentId={row.id}
          whenDone={() => {
            refetchAll().then(hideModal)
          }}
        />
      ),
      hideFooter: true
    })
  }

  function handleOnSelectAll(isSelect: boolean, rows: any[]) {
    if (isSelect) {
      rows.forEach((r: any) => selectedRows.add(r.id))
      setSelectedTotals({
        withVat: rows.reduce((prev, curr) => prev + curr.totalInclVatForSum, 0),
        woVat: rows.reduce((prev, curr) => prev + curr.totalWoVatForSum, 0)
      })
    } else {
      rows.forEach((r: any) => selectedRows.delete(r.id))
      setSelectedTotals({ withVat: 0, woVat: 0 })
    }
    setSelectedRows(new Set(selectedRows))
    return true
  }

  function orderRowExpand(rowId: number | undefined) {
    return {
      renderer: function orderRowExpand(row: any) {
        if (!orderListQR.data || rowId === undefined) return
        const payload = orderListQR.data.orderContentList.items.find(x => x.id === rowId)
        if (!payload) return
        return (
          <OrderListExpand
            key={payload.id}
            data={payload}
            appendAdminNote={appendAdminNote}
            performActionHandler={performActionHandler}
            changeOrderStateHandler={changeOrderStateHandler}
            createReturnUrl={createReturnUrl}
            refetchAll={refetchAll}
          />
        )
      },
      onlyOneExpanding: true,
      showExpandColumn: false,
      expanded: [rowId],
      onExpand: d => setRowToExpand(d.id)
    }
  }

  async function refetchAll() {
    await Promise.all([orderListQR.refetch(), orderContentStatesStatQR.refetch()]).then(() => setSelectedRows(new Set()))
  }
}
