import {
  ColumnAlign,
  ColumnFormatterTypes,
  IRowAction,
  RowActionType,
  TableDefinitionTypes
} from '../../../../services/bootstrapTable/services/tableSpecificationTypes'
import { decimalToLocaleString, moneyToLocaleString } from '../../../../services/dataToString/decimalToLocaleString'
import { IOrderContentListItem } from '../../queries/order-list.query'
import { formatPhoneToDisplay } from '../../../../services/dataToString/formatPhoneToDisplay'
import { formatDateTimeForDisplayShort } from '../../../../services/dataToString/dateTimeFormatter'
import React from 'react'
import dayjs from 'dayjs'
import { ServiceType } from '../../../../services/types/service-type'

export function orderListExpandDataDefinition(allProducts, product, serviceType: ServiceType): TableDefinitionTypes<IOrderContentListItem> {
  return {
    columnDefinition: {
      id: {
        hidden: true
      },
      orderNumber: {
        hidden: true
      },
      createdAt: {
        name: 'Vytvořena'
      },
      phone: {
        name: 'Telefon'
      },
      email: {
        name: 'E-mail'
      },
      ico: {
        name: 'IČO'
      },
      terminal: {
        name: 'Koncovka'
      },
      distance: {
        name: 'Účtované [km]'
      },
      supplierProfitWoVatKc: {
        name: 'Zisk pro dodavatele bez DPH'
      },
      containerCount: {
        name: serviceType === 'MATERIAL_DELIVERY' ? 'Objem' : 'Počet kontejnerů'
      },
      orderNote: {
        name: 'Poznámka zákazníka'
      },
      onsiteContact: {
        name: 'Kontakt na místě'
      },
      adminNote: {
        name: 'Poznámka admin'
      },
      supplierNote: {
        name: 'Poznámka pro dodavatele'
      },
      customerNote: {
        name: 'Poznámka pro zákazníka'
      },
      actions: {
        name: 'Akce',
        columnFormatterType: ColumnFormatterTypes.actionsObject,
        headerStyle: {
          width: '80px'
        },
        align: ColumnAlign.center
      },
      uuid: {
        hidden: true
      }
    },
    sourceToColumnMapping: {
      id: x => x.id,
      orderNumber: x => x.order.orderNumber,
      createdAt: x => dayjs(x.order.createdAt).format('lll'),
      phone: x => <a href={'tel:' + x.order.phone}>{formatPhoneToDisplay(x.order.phone)}</a>,
      email: x => <a href={'mailto:' + x.order.email}>{x.order.email}</a>,
      terminal: x => x.terminal?.name,
      distance: x => (x.distanceTotalKm ? decimalToLocaleString(Math.ceil(x.distanceTotalKm)) : ''),
      ico: x => x.order.ico,
      supplierProfitWoVatKc: x => <i>{moneyToLocaleString(x.supplierProfitWoVatKc, 'CZK', false)}</i>,
      orderNote: x => x.order.orderNote ?? '',
      containerCount: x => {
        if (x.serviceType === 'MATERIAL_DELIVERY') {
          return `${(Math.round((x.weightTonnes / allProducts?.data?.products.find(a => a.name === product.name).tonToCubes) * 100) / 100).toLocaleString(
            'cs-CZ'
          )} m³ (${x.weightTonnes} t)`
        } else {
          return x.containerCount
        }
      },
      adminNote: x => x.adminNote ?? '',
      supplierNote: x => x.supplierNote ?? '',
      customerNote: x => x.customerNote ?? '',
      onsiteContact: x => `${x.onsiteContactFirstname ?? ''} ${x.onsiteContactLastname ?? ''} ${x.onsiteContactPhone ?? ''}`,
      uuid: x => x.order.uuid,
      actions: x => {
        const ret: IRowAction[] = []
        if (x.orderContentState.sysName !== 'Draft')
          ret.push({
            type: RowActionType.codeAction,
            value: 'changeOrderState',
            icon: 'fe-git-pull-request',
            title: 'Změnit stav objednávky'
          })
        if (x.isCancellableByAdmin)
          ret.push({
            type: RowActionType.codeAction,
            value: 'cancelOrder',
            icon: 'fe-x',
            title: 'Zrušit objednávku'
          })
        if (x.orderContentState.sysName === 'Draft')
          ret.push({
            type: RowActionType.codeAction,
            value: 'deleteDraft',
            icon: 'fe-trash',
            title: 'Smazat draft'
          })

        return ret
      }
    }
  }
}
