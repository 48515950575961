import { gql, useQuery } from '@apollo/client'
import { SupplierTransportSupplierLinkEntity, TransportCategory } from '../../../../graphql/aminTypes'

export const supplierTransportSupplierLinkQuery = gql`
  query supplierTransportSupplierLink($branchServiceId: ID!, $transportCategory: TransportCategory!) {
    supplierTransportSupplierLink(branchServiceId: $branchServiceId, transportCategory: $transportCategory) {
      id
      supplierBranchId
      includedKmInFlatRate
      transportFlatRateKc
      supplierBranch {
        supplier {
          id
          name
        }
        specification
        address {
          city
        }
      }
    }
  }
`

export function useSupplierTransportSupplierLinkQuery(branchServiceId: string, transportCategory: TransportCategory) {
  return useQuery<{
    supplierTransportSupplierLink: SupplierTransportSupplierLinkEntity[]
  }>(supplierTransportSupplierLinkQuery, {
    fetchPolicy: 'network-only',
    variables: { branchServiceId, transportCategory }
  })
}
