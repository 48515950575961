import { Box, Button, Typography } from "@mui/material"
import { useContext } from "react"
import { ModalContext } from "../../../components/Modal/ModalProvider"
import AddOrEditMaterial from "./AddOrEditMaterial/AddOrEditMaterial"


const MaterialTopToolbarCustomActions = () => {
    const { showModal, hideModal } = useContext(ModalContext);
    
    return(
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            padding="8px"
        >
            <Typography variant="h6" fontWeight="bold">
                Seznam materiálů pro návoz
            </Typography>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#206BC4',
                    color: 'white', 
                    '&:hover': {
                        backgroundColor: '#185A9D',
                    },
                }}
                onClick={() => {
                    showModal({
                        title: 'Přidat kontejner',
                        modalContent: <AddOrEditMaterial onExit={hideModal} />,
                        hideFooter: true,
                    });
                }}
            >
                Přidat materiál
            </Button>
        </Box>
    )
}

export default MaterialTopToolbarCustomActions